/* Motor Expert - Auto Repair & Service HTML Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Two / Three / Four
5. Main Slider
6. About Us
7. Fun Fact Section / Two
8. Feature Section
9. Services Section
10. Services Section Two
11. Service Detail
12. Why Us Section
13. Gallery Section
14. Project Section
15. Project Detail Section
16. Video And Faq's
17. News Section
18. Team Section
19. Testimonial Section / Two 
20. Testimonial And BLog
21. Blog Detail
22. Styled Pagination
23. Team Page Section
24. Pricing Section
25. Appointment Form Section
26. Appointment Page Style
27. Contact Section
28. Contact Map Section
29. Contact Info Section
30. Clients Section
31. Main Footer
32. Sidebar Page Container
33. Error Page
34. Shop Page Section
35. Shop Details
36. CheckOut Section
37. Cart Section
38. Login Section
39. coming Soon

**********************************************/

@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i|Rubik:300,300i,400,400i,500,500i,700,700i,900,900i');

/*
	font-family: 'Roboto', sans-serif;
	font-family: 'Rubik', sans-serif;
*/

@import url('fontawesome-all.min.css');
@import url('flaticon.min.css');
@import url('animate.min.css');
@import url('owl.min.css');
@import url('jquery.fancybox.min.css');
@import url('jquery.touchspin.min.css');
@import url('hover.min.css');
@import url('jquery-ui.min.css');

/*** 

====================================================================
	Reset
====================================================================

 ***/
 
* {
	margin:0px;
	padding:0px;
	border:none;
	outline:none;
}

/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
	font-size:15px;
	color:#777777;
	line-height:1.8em;
	font-weight:400;
	background:#ffffff;
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center top;
	-webkit-font-smoothing: antialiased;
	font-family: 'Roboto', sans-serif;

}

a{
	text-decoration:none;
	cursor:pointer;
	color:#fb4848;
}

a:hover,a:focus,a:visited{
	text-decoration:none;
	outline:none;
}

h1,h2,h3,h4,h5,h6 {
	position:relative;
	font-weight:700;
	margin:0px;
	background:none;
	line-height:1.6em;
	font-family: 'Rubik', sans-serif;
}

textarea{
	overflow:hidden;
}

p{
	position:relative;
	line-height:1.8em;	
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	margin: 0;
}

::-webkit-input-placeholder{color: inherit;}
::-moz-input-placeholder{color: inherit;}
::-ms-input-placeholder{color: inherit;}

.auto-container{
	position:static;
	max-width:1200px;
	padding:0px 15px;
	margin:0 auto;
}

.page-wrapper{
	position:relative;
	overflow: hidden;
	margin:0 auto;
	width:100%;
	min-width:300px;
}

ul,li{
	list-style:none;
	padding:0px;
	margin:0px;	
}

/*List Style One*/

.list-style-one{
	position:relative;
}

.list-style-one li{
	position:relative;
	font-size:14px;
	line-height: 22px;
	font-weight:400;
	padding-left:40px;
	color:#666666;
	margin-bottom: 20px;
}

.list-style-one li:before{
	position:absolute;
	content:'\f00c';
	left:0px;
	top:3px;
	color:#fb4848;
	font-size:15px;
	font-weight: 900;
	line-height: 22px;
	font-family: 'Font Awesome 5 Free';
}

/*List Style Two*/

.list-style-two{
	position:relative;
}

.list-style-two li{
	position:relative;
	font-size:16px;
	font-weight:400;
	padding-left:70px;
	margin-bottom:40px;
	color:#777777;
}

.list-style-two li .icon{
	position:absolute;
	left:0px;
	top:3px;
	width:40px;
	color:#fb4848;
	font-size:42px;
	line-height:1em;
	text-align:center;
}

.list-style-two li strong{
	font-size:20px;
	color:#36404b;
	font-weight:700;
	display:block;
}

.theme-btn{
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease ;
}

/*Btn Style One*/

.btn-style-one{
	position:relative;
	font-size:14px;
	color:#ffffff;
	padding:15px 30px;
	line-height:20px;
	font-weight:700;
	background:#fb4848;
	text-transform:uppercase;
}

.btn-style-one:hover{
	color:#fb4848;
	background:#ffffff;
}

/*Btn Style Two*/

.btn-style-two{
	position:relative;
	font-size:16px;
	color:#ffffff;
	padding:17px 35px;
	line-height:16px;
	font-weight:700;
	background:#222222;
	text-transform:uppercase;
}

.btn-style-two:hover{
	color:#ffffff;
	background:#fb4848;
}

/*Btn Style Three*/

.btn-style-three{
	position:relative;
	font-size:15px;
	color:#ffffff;
	padding:13px 30px;
	line-height:20px;
	font-weight:400;
	background:#222222;
	border:2px solid #222222;
	text-transform:capitalize;
}

.btn-style-three:hover{
	color:#36404b;
	background:#ffffff;
	border-color:#222222;
}

/*Btn Style Four*/

.btn-style-four{
	position:relative;
	font-size:15px;
	color:#fb4848;
	padding:13px 30px;
	line-height:20px;
	font-weight:400;
	background:#ffffff;
	border:2px solid #ffffff;
	text-transform:capitalize;
}

.btn-style-four:hover{
	background:#fb4848;
	color:#ffffff;
	border-color:#fb4848;
}

/*=== Btn style Five*/

.btn-style-five{
	position:relative;
	font-size:14px;
	color:#ffffff;
	padding:13px 30px;
	line-height:20px;
	font-weight:700;
	background:#fb4848;
	border:2px solid transparent;
	text-transform:uppercase;
}

.btn-style-five:hover{
	color:#fb4848;
	border-color:#fb4848;
	background:#ffffff;
}

.theme_color{
	color:#fb4848;	
}

img{
	display:inline-block;
	max-width:100%;	
}

.preloader{ 
	position:fixed; 
	left:0px; 
	top:0px; 
	width:100%; 
	height:100%; 
	z-index:999999; 
	background-color:#ffffff; 
	background-position:center center; 
	background-repeat:no-repeat;
	background-image:url(../img/icons/preloader.svg);
}

/*** 

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top{
	position:fixed;
	bottom:20px;
	right:15px;
	width:40px;
	height:40px;
	color:#ffffff;
	font-size:15px;
	text-transform:uppercase;
	line-height:40px;
	text-align:center;
	z-index:100;
	cursor:pointer;
	background:#353535;
	display:none;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;		
}

.scroll-to-top:hover{
	color:#ffffff;
	background:#fb4848;
}

/*** 

====================================================================
	Main Header style
====================================================================

***/

.main-header{
	position:relative;
	left:0px;
	top:0px;
	z-index:999;
	width:100%;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

/*=== Header Top ===*/

.main-header .header-top{
	position:relative;
	background-color:#ffffff !important;
}

.main-header .header-top .inner-container{
	position: relative;
}

.main-header .header-top .top-left{
	position:relative;
	float:left;
	margin-left: 330px;
	padding-left: 30px;
}

.main-header .header-top .top-left:before{
	position: absolute;
	left: 0;
	bottom:0;
	height: 50px;
	width: 1000%;
	left: -1000%;
	content: "";
	z-index: 9;
	background-color: #ffffff;
	-moz-transform: skewX(40deg);
	-ms-transform: skewX(40deg);
	transform: skewX(40deg);
}

.main-header .header-top .top-left ul li{
	position:relative;
	float: left;
	margin-left:30px;
	font-size:14px;
	line-height: 30px;
	color:#ffffff;
	padding:10px 0px;
	font-weight:400;
}

.main-header .header-top .top-left ul li a{
	color:#ffffff;
	font-weight:400;
 -moz-transition: all 300ms ease;
 transition: all 300ms ease;
}

.main-header .header-top .top-right{
	position:relative;
	float:right;
}

.main-header .header-top .social-icon-one{
	float: left;
	padding: 10px 0;
}

/*Social Icon One*/

.social-icon-one{
	position:relative;
}

.social-icon-one li{
	position:relative;
	margin-left:15px;
	float: left;
}

.social-icon-one li a{
	position:relative;
	display:block;
	font-size:16px;
	line-height: 30px;
	color:#ffffff;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
    background-color: #4368af;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 1px;
}

.social-icon-one li a span{
	display: inline-block;
}

.social-icon-one li a:hover{
    border-radius: 0;
}

.main-header .header-top .call-btn{
	float: left;
	margin-left: 15px;
}

.main-header .header-top .call-btn a{
	position: relative;
	display: inline-block;
	font-size: 13px;
	line-height: 20px;
	padding: 5px 30px;
	color: #ffffff;
	font-weight: 700;
	background: #222222;
	text-transform: uppercase;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
    width: 160px;
    text-align: center;
}

.main-header .header-top .call-btn a:hover{
	background: #ffffff;
	color: #36404b;
}

/*=== Header Upper ===*/

.main-header .header-upper{
	position:relative;
	z-index:5;
	background-color:#ffffff;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .logo-outer{
	position:relative;
	padding:8px 0px 0px;
	z-index:25;
}

.main-header .logo-outer .logo img{
	display:inline-block;
    position: absolute;
    height: 135px;
    width: 135px;
    max-width: none;
    bottom: -140px;
}

.main-header .header-upper .upper-right{
	position:relative;
	padding: 20px 0 0px;
}

.main-header .info-box{
	position:relative;
	float:left;
	min-height:50px;
	padding-left:60px;
	font-size:14px;
	color:#777777;
	line-height: 24px;
	margin-left:70px;
	margin-bottom: 15px;
}

.main-header .info-box .icon-box{
	position:absolute;
	left:0px;
	top:5px;
	height: 50px;
	line-height: 50px;
	text-align: left;
	vertical-align: top;
}

.main-header .info-box .icon-box span{
	color:#000000;
	font-size:34px;
	line-height: 1em;
}

.main-header .info-box li{
	position:relative;
	line-height:22px;
	color:#444444;
	font-size:14px;
	font-weight:400;
	margin-bottom: 3px;
}

.main-header .info-box li a{
	color: #444444;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-header .info-box li a:hover{
	color: #fb4848;
}

.main-header .info-box strong{
	position:relative;
	font-weight:500;
	color:#36404b;
	font-size:14px;
	font-family: "Rubik", sans-serif;
}

.main-header .header-lower{
	position:relative;
	top:0px;
	width:100%;
	z-index:3;
	background-color:#FFFFFF;
}

/*=== Main Menu ===*/

.main-header .nav-outer{
	position:relative;
	margin-left: 330px;
	padding-left: 40px;
}

.main-header .nav-outer:before{
	position: absolute;
    left: 0;
    bottom: 0;
    height: 50px;
    width: 9%;
    left: 18px;
    content: "";
    z-index: 0;
    background-color: #515ba2;
	-moz-transform: skewX(-40deg);
	-ms-transform: skewX(-40deg);
	transform: skewX(-40deg);
}

.main-header .nav-outer:after {
    background-color: #515ba2;
    height: 50px;
    right: 0;
    width: 100%;
    content: "";
    right: -70%;
    position: absolute;
    float: right;
    top: 0;
    z-index: -1;
}

.main-menu{
	position:relative;	
	background: #515ba2;
}

.main-menu .navbar-collapse{
	padding:0px;	
}

.main-menu .navigation{
	position:relative;
	margin:0px;
}

.main-menu .navigation > li{
	position:relative;
	float:left;
	margin-right:5px;
}

.main-menu .navigation > li:last-child{
	margin-right:0px;
}

.main-header .sticky-header .main-menu .navigation > li{
	margin:0px;
	border:0px;
	padding:0;
}

.main-menu .navigation > li > a{
	position:relative;
	display:block;
	font-size:15px;
	color:#ffffff;
	line-height:20px;
	font-weight:400;
	opacity:1;
	text-transform:uppercase;
	padding:15px 15px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease ;
}

.main-header .sticky-header .main-menu .navigation > li > a{
	color: #ffffff;
	padding:15px 15px !important;
	line-height:30px;
	font-size:16px;
	font-weight:500;
	min-height:0px;
	background:none;
	border:0px;
	margin-right:0px;
	text-transform:capitalize;
}

.main-menu .navigation > li:hover > a {
   color: #ffffff;
}

.header-style-two .main-menu .navigation > li.current > a, .header-style-three .main-menu .navigation > li.current > a {
   border-bottom: 0px;
}

.header-style-three .main-menu .navigation > li.current > a {
   background-color: transparent;
}

.sticky-header .main-menu .navigation > li > a:before{
	display:none;
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a,
.main-menu .navigation > li.current-menu-item > a{
	opacity:1;
	color:#ffffff;
}

.main-menu .navigation > li:hover > a:after{
	opacity:1;
}

.main-menu .navigation > li > ul{
	position:absolute;
	left:0px;
	top:100%;
	width:250px;
	padding:0px 0px;
	z-index:100;
	background:#2b2b2b;
	border-top:2px solid #fb4848;
	-ms-transform:scaleY(0);
	-moz-transform:scaleY(0);
	transform:scaleY(0);
	-ms-transform-origin:top;
	-moz-transform-origin:top;
	transform-origin:top;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.main-menu .navigation > li > ul.from-right{
	left:auto;
	right:0px;	
}

.main-menu .navigation > li > ul > li{
	position:relative;
	width:100%;
	border-bottom:1px solid rgba(255,255,255,0.10);
}

.main-menu .navigation > li > ul > li:last-child{
	border-bottom:none;	
}

.main-menu .navigation > li > ul > li > a{
	position:relative;
	display:block;
	padding:10px 20px;
	line-height:24px;
	font-weight:500;
	font-size:15px;
	color:#ffffff;
	text-align:left;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li:hover > a{
	color:#ffffff;
	background:#fb4848;
}

.main-menu .navigation > li > ul > li.dropdown > a:after{
	font-family: 'Font Awesome 5 Free';
	content: "\f105";
	position:absolute;
	right:15px;
	top:11px;
	width:10px;
	height:20px;
	display:block;
	line-height:20px;
	font-size:16px;
	font-weight:normal;
	text-align:center;
	z-index:5;
	font-weight: 900;
}

.main-menu .navigation > li > ul > li > ul{
	position:absolute;
	left:100%;
	top:-2px;
	width:250px;
	padding:0px;
	z-index:100;
	display:none;
	background:#2b2b2b;
	border-top:2px solid #fb4848;
	-ms-transform:scaleX(0);
	-moz-transform:scaleX(0);
	transform:scaleX(0);
	-ms-transform-origin:left;
	-moz-transform-origin:left;
	transform-origin:left;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.main-menu .navigation > li > ul > li > ul > li{
	position:relative;
	width:100%;
	border-bottom:1px solid rgba(255,255,255,0.10);
}

.main-menu .navigation > li > ul > li > ul > li:last-child{
	border-bottom:none;	
}

.main-menu .navigation > li > ul > li  > ul > li > a{
	position:relative;
	display:block;
	padding:10px 20px;
	line-height:24px;
	font-weight:500;
	font-size:15px;
	color:#ffffff;
	text-align:left;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li  > ul > li > a:hover{
	color:#ffffff;
	background:#fb4848;
}

.main-menu .navigation > li.dropdown:hover > ul{
	-ms-transform:scaleY(1);
	-moz-transform:scaleY(1);
	transform:scaleY(1);
	visibility:visible;
	opacity:1;
	top:100%;	
}

.main-menu .navigation li > ul > li.dropdown:hover > ul{
	-ms-transform:scaleX(1);
	-moz-transform:scaleX(1);
	transform:scaleX(1);
	visibility:visible;
	opacity:1;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;	
}

.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn{
	position:absolute;
	right:10px;
	top:8px;
	width:34px;
	height:30px;
	border:1px solid #ffffff;
	text-align:center;
	font-size:16px;
	line-height:30px;
	color:#ffffff;
	cursor:pointer;
	z-index:5;
	display:none;
}

.search-box-outer{
	position: relative;
    float: right;
    color: #ffffff;
    margin-left: 30px;
    padding: 10px 0px;
}

.main-header .search-box-btn{
	position:relative;
	display:block;
	width: 30px;
	text-align: center;
	font-size:12px;
	line-height: 20px;
	color: #d9d9d9;
	padding:4px 5px;
	margin:0px;
	cursor:pointer;
	background:none;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .search-box-btn:hover{
	color:#fb4848;	
}

.header-style-two.main-header .search-box-btn:hover{
	color:#ffffff !important;	
}
.main-header  .dropdown-menu{
	top:38px;
	right: 0;
	border-top:3px solid #fb4848;
	padding:0px;
	width:280px;
	border-radius:0px;
}

.main-header .search-box-outer .dropdown-menu > li{
	padding:0px;
	border:none;
	background:none;
}

.main-header .search-panel .form-container{
	padding:25px 20px;	
}

.main-header .search-panel .form-group{
	position:relative;
	margin:0px;	
}

.main-header .search-panel input[type="text"],
.main-header .search-panel input[type="search"],
.main-header .search-panel input[type="password"],
.main-header .search-panel select{
	display:block;
	width:100%;
	line-height:24px;
	padding:7px 40px 7px 15px;
	height:40px;
	color:#000000;
	border:1px solid #e0e0e0;
	background:#ffffff;	
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .search-panel input:focus,
.main-header .search-panel select:focus{
	border-color:#fb4848;	
}

.main-header .search-panel .search-btn{
	position:absolute;
	right:0px;
	top:0px;
	width:40px;
	height:40px;
	text-align:center;
	color:#555555;
	font-size:12px;
	background:none;
	cursor:pointer;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .search-panel .search-btn:hover{
	color:#fb4848;	

}

/*Sticky Header*/

.sticky-header{
	position:fixed;
	opacity:0;
	visibility:hidden;
	left:0px;
	top:0px;
	width:100%;
	padding:0px 0px;
	z-index:-1;
}

.sticky-header .logo{
	padding:5px 0px 2px;	
}

.fixed-header .sticky-header{
	z-index:999;
	opacity:1;
	visibility:visible;
	background-color:#ffffff;
	-ms-box-shadow: 0 0 20px rgba(51,51,51,.1);
	-o-box-shadow: 0 0 20px rgba(51,51,51,.1);
	box-shadow: 0 0 20px rgba(51,51,51,.1);
}

.main-header .sticky-header .main-menu .navigation > li:hover > a,
.main-header .sticky-header .main-menu .navigation > li.current > a,
.main-header .sticky-header .main-menu .navigation > li.current-menu-item > a{
	background:#fb4848;
	color:#ffffff;
	opacity:1;
}

/*======================================
			 Header Style Two
========================================*/

.header-style-two{
	background-color: #222222;
}

.header-style-two .outer-container{
	position: relative;
	padding-left: 270px;
}

.header-style-two .logo-outer{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	padding:35px 0px;
	width: auto;
}

.header-style-two .header-top{
	position: relative;
	width: 100%;
}

.header-style-two .header-top:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 1000%;
	background-color: #fb4848;
	content: "";
}

.header-style-two .header-top .social-icon-one{
	padding: 0;
	margin-left: 40px;
}

.header-style-two .header-top .top-left ul li{
	margin-left: 20px;
}

.header-style-two .header-top .top-left ul li:hover a{
	color: #36404b;
}

.header-style-two .header-top .top-left{
	margin-left: 0;
	padding-left: 0;
}

.header-style-two .header-top .top-left:before{
	display: none;
}

.header-style-two .nav-outer:before{
	display: none;
}

.header-style-two .header-top .top-right{
	position: relative;
	float: right;
	padding: 9px 0;
}

.header-style-two .header-top .top-right p{
	position: relative;
	float: left;
	font-size: 14px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
}

.header-style-two .header-top .top-right p i{
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	margin-right: 15px;
}

.header-style-two .search-box-outer{
	padding: 0;
	top: 2.5px;
	margin-left: 25px;
}

.header-style-two .search-box-btn{
	color: #ffffff;
}

.header-style-two .dropdown-menu{
	top: 36px;
}

.header-style-two .search-box-btn:hover{
	color: #36404b; 	
}

.header-style-two .header-upper{
	background-color: #222222;
}

.header-style-two .nav-outer{
	padding-left:40px;
	margin-left: 0;
	background-color: #ffffff;
}

.header-style-two .main-menu:before{	
	position: absolute;
	left: 270px;
	top: 0;
	height: 100%;
	width: 1000%;
	background-color: #ffffff;
	content: "";
}

.header-style-two .main-menu .navigation > li{
	padding:35px 15px;
	margin-right: 5px;
	text-align: center;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.header-style-two .main-menu .navigation > li > a{
	padding:0px 0px 0px 0px;
	color:#36404b;
	font-size:15px;
	line-height: 30px;
	margin-right:0px;
	font-weight:600;
	text-transform:uppercase;
	background: none;
}

.header-style-two .header-upper .main-menu .navigation > li.current,
.header-style-two .header-upper .main-menu .navigation > li:hover{
	background-color: #dddddd;
}

.header-style-two .header-upper .main-menu .navigation > li.current > a,
.header-style-two .header-upper .main-menu .navigation > li:hover > a{
	color:#fb4848;
}

.header-style-two .header-upper .outer-box{
	position: relative;
	float: right;
}

.main-header .cart-btn{
	position: relative;
	float: left;
	padding: 29px 0 30px;
}

.main-header .cart-btn a{
	position: relative;
	display: inline-block;
	font-size: 30px;
	line-height: 40px;
	color: #181818;
}

.main-header .cart-btn .count{
	position: absolute;
	right: -11px;
	top: 0;
	height: 22px;
	width: 22px;
	line-height: 22px;
	color: #ffffff;
	font-size: 12px;
	text-align: center;
	font-weight: 400;
	background-color: #fb4848;
	border-radius: 50%;
}

.header-style-two .header-upper .call-btn{
	position: relative;
	float: left;
	padding: 30px 0;
	margin-left: 40px;
}

.header-style-two .header-upper .call-btn a{
	padding: 12px 30px;
	font-size: 12px;
}

/*======================================
			 Header Style Three
========================================*/

.header-style-three{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	background-color: transparent;
	z-index: 999;
}

.header-style-three:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #222222;
	opacity: .30;
	content: "";
	z-index: -1;
}

.header-style-three .header-lower{
	background-color: transparent;
}

.header-style-three .header-top{
	background-color: #222222 !important;
}

.header-style-three .header-top .top-left{
	margin-left: 0;
	padding-left: 0;
}

.header-style-three .header-top .top-left ul li:last-child:after,
.header-style-three .header-top .top-left:before{
	display: none;
}

.header-style-three .header-top .top-left ul li{
	position: relative;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-weight: 400;
	padding: 20px 0;
	margin-left: 0;
	padding-right: 20px;
	margin-right: 20px;
}

.header-style-three .header-top .top-left ul li a{
	color: #999999;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.header-style-three .header-top .top-left ul li i{
	color: #fb4848;
	margin-left: 10px;
}

.header-style-three .header-top .top-left ul li a:hover{
	color: #ffffff;
}

.header-style-three .header-top .top-left ul li:after{
	position: absolute;
	right: 0;
	top: 23px;
	height: 14px;
	width: 1px;
	background-color: #c0c2c4;
	content: "";
}

.header-style-three .header-top .top-right{
	position: relative;
	padding: 20px 0;
}

.header-style-three .header-top .top-right p{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-weight: 400;
}

.header-style-three .header-top .top-right p i{
	display: inline-block;
	margin-right: 10px;
	color: #fb4848;
}

.header-style-three .nav-outer{
	padding-left: 0;
	margin-left: 0;
	float: right;
}

.header-style-three .logo-box{
	position: relative;
	float: left;
	padding: 32px 0;
}

.header-style-three .nav-outer:before{
	display: none;
}

.header-style-three .main-menu .navigation > li{
	padding: 42px 0;
	margin-right: 35px;
}

.header-style-three .main-menu .navigation > li > a{
	font-weight: 600;
	padding: 15px 0;
	font-size: 16px;
}

.header-style-three .outer-box{
	position: relative;
	float: right;
}

.header-style-three .cart-btn{
	position: relative;
	float: left;
	padding: 46px 0 47px;
}

.header-style-three .cart-btn a{
	color: #ffffff;
	font-size: 25px;
}

.header-style-three  .search-box-btn{
	border:0;
	font-size: 16px;
	padding: 0;
	color: #ffffff;
}

.header-style-three .dropdown-outer{
    padding: 57px 0;
    float: left;
    margin-left: 40px;
}

.main-header .dropdown-menu{
	top: 36px;
}

.header-style-three .dropdown-menu{
	top: 130px;
}

/*======================================
			 Header Style Four
========================================*/

.header-style-four{
	position: relative;
	background-color: #ffffff;
	z-index: 999;
}

.header-style-four .header-lower{
	background-color: transparent;
}

.header-style-four .header-top{
	background-color: #222222 !important;
}

.header-style-four .header-top .top-left{
	margin-left: 0;
	padding-left: 0;
}

.header-style-four .header-top .top-left ul li:last-child:after,
.header-style-four .header-top .top-left:before{
	display: none;
}

.header-style-four .header-top .top-left ul li{
	position: relative;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-weight: 400;
	padding: 20px 0;
	margin-left: 0;
	padding-right: 20px;
	margin-right: 20px;
}

.header-style-four .header-top .top-left ul li a{
	color: #999999;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.header-style-four .header-top .top-left ul li i{
	color: #fb4848;
	margin-left: 10px;
}

.header-style-four .header-top .top-left ul li a:hover{
	color: #ffffff;
}

.header-style-four .header-top .top-left ul li:after{
	position: absolute;
	right: 0;
	top: 23px;
	height: 14px;
	width: 1px;
	background-color: #c0c2c4;
	content: "";
}

.header-style-four .header-top .top-right{
	position: relative;
	padding: 20px 0;
}

.header-style-four .header-top .top-right p{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-weight: 400;
}

.header-style-four .header-top .top-right p i{
	display: inline-block;
	margin-right: 10px;
	color: #fb4848;
}

.header-style-four .nav-outer{
	padding-left: 0;
	margin-left: 0;
	float: right;
}

.header-style-four .logo-box{
	position: relative;
	float: left;
	padding: 37px 0;
}

.header-style-four .nav-outer:before{
	display: none;
}

.header-style-four .main-menu .navigation > li{
	padding: 42px 0;
	margin-right: 35px;
}

.header-style-four .main-menu .navigation > li > a{
	font-weight: 600;
	padding: 15px 0;
	font-size: 16px;
	color: #36404b;
}

.header-style-four .main-menu .navigation > li:hover > a,
.header-style-four .main-menu .navigation > li.current > a{
	color: #fb4848;
}

.header-style-four .main-menu .navigation > li.current > a, .header-style-three .main-menu .navigation > li.current > a, .header-style-two .main-menu .navigation > li.current > a {
   background-color: transparent;
}

.main-menu .navigation > li > a:hover,
.main-menu .navigation > li.current > a {
   color: #ffffff;
   background-color: #6973bb !important;
}

.header-style-four .outer-box{
	position: relative;
	float: right;
}

.header-style-four .cart-btn{
	position: relative;
	float: left;
	padding: 46px 0 47px;
}

.header-style-four .cart-btn a{
	color: #36404b;
	font-size: 25px;
}

.header-style-four  .search-box-btn{
	border:0;
	font-size: 16px;
	padding: 0;
	color: #36404b;
}

.header-style-four .dropdown-outer{
    padding: 57px 0;
    float: left;
    margin-left: 40px;
}

.header-style-four .dropdown-menu{
	top: 132px;
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title{
	position: relative;
	margin-bottom: 30px;
}

.sec-title h2{
	position: relative;
	display: block;
	font-size: 30px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	text-transform: uppercase;
}

.sec-title h3{
	position: relative;
	display: block;
	font-size: 24px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	text-transform: uppercase;
}

.sec-title .separator{
	position: relative;
	display: inline-block;
	font-size: 32px;
	line-height: 40px;
	height: 40px;
	width: 50px;
	color: #fb4848;
	margin:25px 115px 0;
}

.sec-title .separator span{
	position: relative;
	display: block;
	text-align: center;
}

.sec-title .separator:before{
	position: absolute;
	right: 100%;
	top: 18px;
	content: "";
	background: #d9d9d9 padding-box content-box ;
	padding: 2px 0 2px 28px;
	border-top: 1px solid #d9d9d9;
	width: 115px;
	height: 6px;
}

.sec-title.text-center .separator:after{
	position: absolute;
	left: 100%;
	top: 18px;
	content: "";
	background: #d9d9d9 padding-box content-box ;
	padding: 2px 28px 2px 0; ;
	border-top: 1px solid #d9d9d9;
	width: 115px;
	height: 6px;
}

.sec-title.light .title{
	color: #888888;
}

.sec-title.light h2{
	color: #ffffff;
}

.sec-title.light .separator:after,
.sec-title.light .separator:before{
	background-color: #787878;
	border-color: #787878;
}

/*** 

====================================================================
			Page Title
====================================================================

***/

.page-title{
	position:relative;
	/*padding:30px 0px;*/
	padding:10px 0px;
	text-align:center;
	box-shadow: 0px 3px 30px -6px rgba(0,0,0,0.75);
}

.page-title:before{
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	position:absolute;
	background-color: #515ba2;
}

.page-title .inner-container{
	position: relative;
}

.page-title .inner-container:before {
    background: #FFF;
    width: 100%;
    height: 1px;
    z-index: 9;
    position: absolute;
    top: 24px;
}

.page-title h1{
	position:relative;
	font-size:30px;
	color:#ffffff;
	line-height: 50px;
	font-weight: 500;
	text-transform:uppercase;
	text-align: center;
    background: #515ba2;
    display: inline-block;
    z-index: 10;
    padding: 0 10px;
}

.page-title .bread-crumb {
	position: relative;
	float: right;
	padding: 10px 0;
}

.page-title .bread-crumb li{
	position: relative;
	float: left;
	font-size: 16px;
	line-height: 30px;
	color: #fb4848;
	font-weight: 400;
	cursor: default;
	padding-right:  10px;
	margin-right: 10px;
}

.page-title .bread-crumb li:before{
	position: absolute;
	right: -3px;
	top: 0;
	line-height: 30px;
	color: #ffffff;
	content: "-";
	font-size: 16px;
}

.page-title .bread-crumb li:last-child{
	margin-right: 0;
	padding-right: 0;
}

.page-title .bread-crumb li:last-child:before{
	display: none;
}

.page-title .bread-crumb li a{
	color:#ffffff;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.page-title .bread-crumb li a:hover{
	color:#fb4848;	
}

/*** 

====================================================================
	Main Slider One
====================================================================

***/

.main-slider{
	position:relative;
	z-index:10;
	overflow:hidden;
}

.main-slider .tp-caption{
	z-index:5;	
}

.main-slider .tp-dottedoverlay{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

.main-slider h4{
	font-size:24px;
	color:#ffffff;
	font-weight:400;
	line-height:1.2em;
	font-family: 'Roboto', sans-serif;
}

.main-slider h2{
	font-size:42px;
	color:#ffffff;
	font-weight:500;
	line-height:1.2em;
	text-transform: uppercase;
}

.main-slider .text{
	position: relative;
	font-weight:400;
	font-size:16px;
	line-height:30px;
	color:#ffffff;
}

.main-slider .theme-btn{
	display: inline-block;
	padding: 15px 40px;
}

.main-slider .theme-btn:hover{
	background: #ffffff;
}

.main-slider .tp-bannertimer,
.main-slider .tp-bullets{
	display:none !important;	
}

/*** 

====================================================================
					About Us								
====================================================================

***/

.about-us{
	position: relative;
	padding: 100px 0 80px;
}

.about-us .content-column{
	position: relative;
	margin-bottom: 40px;
}	

.about-us .content-column h2{
	position: relative;
	font-size: 30px;
	color: #36404b;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 1.2em;
	top: -5px;
	margin-bottom: 10px;
}

.about-us .content-column h4{
	position: relative;
	display: block;
	font-size: 24px;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 20px;
}

.about-us .content-column .primary-text{
	position: relative;
	display: block;	
	font-size: 16px;
	line-height: 30px;
	color: #fb4848;
	border-left: 1px solid #222222;
	padding-left: 30px;
	margin-bottom: 20px;
}

.about-us .content-column .text{
	position: relative;
	font-size: 14px;
	line-height: 30px;
	color: #666666;
	font-weight: 400;
	margin-bottom: 20px;
	text-align: justify;
}

.about-us .content-column .list-style-one li{
	float: left;
	width: 50%;
	font-size: 14px;
	line-height: 30px;
	margin: 0;
	padding-left: 35px;
}

.about-us .content-column .list-style-one li:before{
	color: #1c63b8;
}

.about-us .image-column{
	position: relative;
}

.about-us .image-column .image-box{
	position: relative;
	margin-bottom: 30px;
}

.about-us .image-column .image-box a{
	display: block;
}

.about-us .image-column .image-box img{
	display: block;
	width: 100%;
	height: auto;
}

.about-us .image-column .column h3{
	position: relative;
	font-size: 24px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 20px;
}

.about-us .image-column .column h3 a{
	color: #36404b;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.about-us .image-column .column h3 a:hover{
	color: #fb4848;
}

.about-us .image-column .column p{
	position: relative;
	font-size: 14px;
	line-height: 30px;
	color: #666666;
	margin-bottom: 15px;
}

.about-us .image-column .column .read-more{
	position: relative;
	font-size: 14px;
	color: #fb4848;
	font-weight: 500;
	text-transform: capitalize;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.about-us .image-column .column .read-more:hover{
	color: #36404b;
}

/*** 

====================================================================
			Fun Fact Section								
====================================================================

***/

.fun-fact-section{
	position: relative;
	padding: 70px 0 40px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.fun-fact-section:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background:#222222;
	opacity:.9;
	content: "";
}

.fun-fact-section .count-box{
	position: relative;
	margin-bottom: 30px;
}

.fun-fact-section .count-box .inner-box{
	position: relative;
	display: block;
	border: 2px solid #fb4848;
}

.fun-fact-section .count-box .inner-box:after{
	display: table;
	clear: both;
}

.fun-fact-section .count-box .icon-box{
	position: relative;
	float: left;
	height: 55px;
	width: 55px;
	line-height: 55px;
	text-align: center;
	font-size: 30px;
	color: #ffffff;
	margin-right: 5px;
}

.fun-fact-section .count-box .count-text{
	position: relative;
	display: inline-block;
	font-size: 24px;
	line-height: 25px;
	color: #ffffff;
	font-weight: 700;
	padding: 15px 0;
	color: #ffffff;
}

.fun-fact-section .count-box .counter-title{
	position: relative;
	display: block;
}

.fun-fact-section .count-box .counter-title h5{
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	font-weight: 500;
	color: #ffffff;
	padding: 6px  25px 6px 13px;
	text-transform: uppercase;
	background-color: #fb4848;
	min-width: 145px;
}

.fun-fact-section .count-box .counter-title h5:before{
	position: absolute;
	right: -15px;
	top: 0;
	content: "";
	width: 0;
	height: 0;
	border-bottom: 42px solid #fb4848;
	border-right: 15px solid transparent;
}

/*** 

====================================================================
				Fun Fact Two								
====================================================================

***/

.fun-fact-two{
	position: relative;
	padding: 120px 0 40px;
}

.fun-fact-two.no-pd-top{
	padding-top: 0;
}

.fun-fact-two .count-box{
	position: relative;
	margin-bottom: 50px;
}

.fun-fact-two .count-box .inner-box{
	position: relative;
	background-color: #f8f8f8;
	padding: 35px 35px;
	min-height: 250px;
	-ms-box-shadow: 6px 12px 10px 0 rgba(0,0,0,.17);
	-o-box-shadow: 6px 12px 10px 0 rgba(0,0,0,.17);
	box-shadow: 6px 12px 10px 0 rgba(0,0,0,.17);
}

.fun-fact-two .count-box .icon{
	position: absolute;
	right: 0;
	bottom: 0;
	text-align: right;
	font-size: 130px;
	line-height: 1em;
	color: #e2e2e2;
	font-weight: 400;
}

.fun-fact-two .count-box .count-text{
	position: relative;
	display: block;
	font-size: 48px;
	line-height: 1em;
	color: #36404b !important;
	font-weight: 700;
	padding-bottom: 20px;
	margin-bottom: 25px;
}

.fun-fact-two .count-box .count-text:before{
	position: absolute;
	left: 0;
	bottom: 0;
	height: 2px;
	width: 60px;
	background-color: #222222;
	content: "";
}

.fun-fact-two .count-box .counter-title h3{
	position: relative;
	display: block;
	font-size: 24px;
	line-height: 1.2em;
	color: #36404b !important;
	font-weight: 500;
}

/*=== Three ===*/

.fun-fact-two .count-box:nth-child(4n + 2) .inner-box{
	background-color: #cfecf3;
}

.fun-fact-two .count-box:nth-child(4n + 2) .counter-title h3,
.fun-fact-two .count-box:nth-child(4n + 2) .count-text{
	color: #36404b;
}

.fun-fact-two .count-box:nth-child(4n + 2) .count-text:before{
	background-color: #36404b !important;
}

.fun-fact-two .count-box:nth-child(4n + 2) .icon{
	color: rgba(0,0,0,.10);
}

/*=== Three ===*/

.fun-fact-two .count-box:nth-child(4n + 3) .inner-box{
	background-color: #22abdd;
}

.fun-fact-two .count-box:nth-child(4n + 3) .counter-title h3,
.fun-fact-two .count-box:nth-child(4n + 3) .count-text{
	color: #36404b;
}

.fun-fact-two .count-box:nth-child(4n + 3) .count-text:before{
	background-color: #36404b !important;
}

.fun-fact-two .count-box:nth-child(4n + 3) .icon{
	color: rgba(0,0,0,.10);
}

/*=== Four ===*/

.fun-fact-two .count-box:nth-child(4n + 4) .inner-box{
	background-color: #515aa2;
}

.fun-fact-two .count-box:nth-child(4n + 4) .counter-title h3,
.fun-fact-two .count-box:nth-child(4n + 4) .count-text{
	color: #36404b;
}

.fun-fact-two .count-box:nth-child(4n + 4) .icon{
	color: rgba(0,0,0,.10);
}

/*** 

====================================================================
						Feature Section								
====================================================================

***/

.feature-section{
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 100px 0 100px;
}

.features-carousel{
	position: relative;
	display: block;
}

.feature-section .title-box{
	position: relative;
	display: block;
	padding-bottom: 35px;
	margin-bottom: 55px;
}

.feature-section .title-box:before,
.feature-section .title-box:after{
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: #aaaaaa;
	content: "";
	left: 0;
	bottom: 0;
}

.feature-section .title-box:before{
	bottom: 5px;
}

.feature-section .title-box h2{
	position: relative;
	font-size: 30px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	text-transform: uppercase;
}

.feature-block{
	position: relative;
}

.feature-block .inner-box{
	position: relative;
	background-color: #ffffff;
	border-radius: 0 15px 0 0;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	overflow: hidden;
}

.feature-block .image-box{
	position: relative;
}

.feature-block .image-box a{
	display: block;
}

.feature-block .image-box img{
	display: block;
	width: 100%;
	height: auto;
}

.feature-block .image-box .price{
	position: absolute;
	left: 0;
	top: 0;
	height: 45px;
	width: 60px;
	font-size: 24px;
	line-height: 45px;
	color: #ffffff;
	font-weight: 700;
	background-color: #fb4848;
	text-align: center;
}

.feature-block .image-box .price sup{
	font-size: 14px;
}

.feature-block .lower-content{
	position: relative;
	background-color: #ffffff;
	text-align: center;
	padding: 28px 15px;
	border: 1px dashed #aaaaaa;
}

.feature-block .lower-content h3{
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.feature-block .lower-content h3 a{
	color: #36404b;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.feature-block .lower-content h3 a:hover{
	color: #fb4848;
}

.feature-block .lower-content .read-more{
	position: relative;
	display: inline-block;
	font-size: 12px;
	line-height: 18px;
	color: #666666;
	font-weight: 400;
	padding: 10px 20px;
	border: 1px solid #dddddd;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.feature-block .lower-content .read-more i{
	color: inherit;
	margin-right: 5px;
}

.feature-block .lower-content .read-more:hover{
	color: #ffffff;
	background-color: #fb4848;
}

.features-carousel .owl-nav{
    position: absolute;
    right: 20px;
    top: -115px;
    z-index: 99;
}

.features-carousel .owl-prev, 
.features-carousel .owl-next {
    position: absolute;
    height: 35px;
    width: 35px;
    right: 0;
    line-height: 35px;
    text-align: center;
    font-size: 16px;
	color: #222222; 
	background: #ffffff;
	border: 1px solid #dddddd;
	-moz-transition:all 300ms ease;
 transition: all 300ms ease;
}

.features-carousel .owl-prev{
	left: -80px;
}

.features-carousel .owl-prev:hover, 
.features-carousel .owl-next:hover{
	color: #fb4848;
}

/*** 

====================================================================
				Services Section
====================================================================

***/

.services-section{
	position: relative;
	padding: 70px 0 30px 0;
}

.services-carousel{
	position: relative;
	padding-bottom: 65px;
}

.service-block{
	position: relative;
	margin-bottom: 20px;
}

.service-block .inner-box{
	position: relative;
	overflow: hidden;
}

.service-block .image-box {
	position: relative;
}

.service-block .image-box img{
	display: block;
	width: 100%;
	height: auto;
}

.service-block .image-box .title{
	/*position: absolute;
	left: 0;
	bottom: 0;*/
	width: 100%;
	height: auto;
	padding:5px 0;
	text-align: center;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

/*.service-block .inner-box:hover .image-box .title{
	-webkit-transform: translateY(100%);
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	-o-transform: translateY(100%);
	transform: translateY(100%);
}*/

/*.service-block .image-box .title:before{
	position: absolute;
	left: 0;
	border-bottom: 65px solid rgba(54,64,75, .9);
	border-left: 40px solid transparent;
	border-right: 40px solid transparent;
	height: 0;
	width: 100%;
	content: "";
	bottom: 0;
}*/

.service-block .image-box .title h4{
	position: relative;
	font-size: 15px;
	line-height: 25px;
	color: #5a63ae;
	font-weight: 500; 	
}

.service-block .caption-box{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: auto;
	background-color: rgba(251,72,72, .80);
	padding: 36px 30px;
	-moz-transition: all 500ms ease;
	     transition: all 500ms ease;
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	transform: translateY(100%);
}

/*.service-block .inner-box:hover .caption-box{
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}*/

.service-block .caption-box .title-box{
	position: relative;
	margin-bottom: 15px;
	padding: 17.5px 0;
}

.service-block .caption-box .title-box h4{
	font-size: 18px;
	line-height: 25px;
	color: #ffffff;
	font-weight: 500; 
}

.service-block .caption-box .title-box h4 a{
	color: #ffffff;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.service-block .caption-box .title-box h4 a:hover{
	color: #36404b;
}

.service-block .caption-box .title-box .icon{
	position: absolute;
	left: 0;
	top: 0;
	height: 60px;
	width: 60px;
	line-height: 60px;
	text-align: center;
	font-size: 42px;
	color: #ffffff;
}

.service-block .caption-box p{
	position: relative;
	display: block; 
	font-size: 14px;
	line-height: 23px;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 20px;
}

.service-block .caption-box .read-more{
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 18px;
	color: #ffffff;
	font-weight: 500;
	text-transform: uppercase;
}

.service-block .caption-box .read-more:hover{
	text-decoration: underline;
}

.service-block .caption-box .read-more i{
	margin-left: 10px;
}

.services-carousel .owl-nav{
	display: none;
}

.services-carousel .owl-dots{
	position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.services-carousel .owl-dots .owl-dot{
	position:relative;
	width:30px;
	margin:0 4px;
	padding: 5px 0;
	display:inline-block;
	border-bottom: 2px solid #2e3760;
	-moz-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.services-carousel .owl-dots .owl-dot.active,
.services-carousel .owl-dots .owl-dot:hover{
	border-bottom: 4px solid #222222;
}

.services-section.alternate{
	padding: 0;
	z-index: 99;
}

.services-section.alternate .services-carousel{
	margin-top: -195px;
}

.services-page-section{
	padding: 60px 0;
}

.services-page-section .service-block{
	margin-bottom: 30px;
}

.services-page-section .styled-pagination{
	display: block;
	margin-top: 90px;
}

/*** 

====================================================================
					Services Section Two							
====================================================================

***/

.services-section-two{
	position: relative;
	padding: 100px 0 100px;
	background-color: #f5f5f5;
	background-repeat: no-repeat;
	background-position: center;
}

.services-section-two.alternate{
	background-color: #ffffff;
}

.service-block-two{
	position: relative;
	display: block;
	margin-bottom: 70px;
}

.service-block-two .inner-box{
	position: relative;
}

.service-block-two .icon-box{
	position: relative;
	line-height: 1em;
	text-align: left;
	font-size: 60px;
	color: #aaaaaa;
	margin-bottom: 35px;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.service-block-two:hover .icon-box{
	color: #fb4848;
}

.service-block-two h3{
	position: relative;
	font-size: 20px;
	line-height: 30px;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 20px;
}

.service-block-two h3 a{
	color: #36404b;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.service-block-two h3 a:hover{
	color: #fb4848;
}

.service-block-two p{
	position: relative;
	font-size: 16px;
	line-height: 30px;
	color: #666666;
	font-weight: 400;
	margin-bottom: 25px;
}

.service-block-two .read-more{
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height:30px;
	color: #fb4848;
	font-weight: 500;
	text-transform: capitalize;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}	

.service-block-two .read-more:hover{
	text-decoration: underline;
}

.service-list{
	position: relative;
}

.services-list .service{
	position: relative;
	min-height: 70px;
	margin-bottom: 30px;
}

.services-list .service .inner-box{
	position: relative;
	padding-left: 100px;
	min-height: 70px;
}

.services-list .service .icon-box{
	position: absolute;
	left: 0;
	top: 0;
	height: 70px;
	width: 70px;
	line-height: 66px;
	border: 2px solid #aaaaaa;
	color: #aaaaaa;
	font-size: 40px;
	text-align: center;
	background-color: #ffffff;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.services-list .service:hover .icon-box{
	border-color: #fb4848;
	background-color: #fb4848;
	color: #ffffff;
}

.services-list .service h3{
	position: relative;
	font-size: 18px;
	line-height: 28px;
	color: #36404b;
	font-weight: 500;
	padding-top: 5px;
	margin-bottom: 5px;
}

.services-list .service h3 a{
	color: #36404b;
	display: inline-block;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.services-list .service:hover h3 a{
	color: #fb4848;
}

/*** 

====================================================================
			Service Detail								
====================================================================

***/

.service-detail-section{
	position: relative;
	padding: 60px 0 80px;
}

.service-detail{
	position: relative;
}

.service-detail-slider{	
	position: relative;
	margin-bottom: 40px;
}

.service-detail-slider .owl-nav{
	position: absolute;
	left: 0;
	top: 50%;
	height: 120px;
	width: 100%;
	margin-top: -60px;
} 	

.service-detail-slider .owl-prev,
.service-detail-slider .owl-next{
	position: absolute;
	left: 0px;
	top: 0;
	height: 120px;
	width: 30px;
	color: #ffffff;
	background: rgba(255,255,255,.20);
	line-height: 30px;
	padding:45px 0;
	font-size: 24px;
	font-weight: 400;
	text-align: center;
	z-index: 1;
}

.service-detail-slider .owl-next{
	right: 0px;
	left: auto;
}

.service-detail h2{
	position: relative;
	font-size: 30px;
	line-height: 1.3em;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 25px;
	text-transform: uppercase;
}

.service-detail h3{
	position: relative;
	font-size: 24px;
	line-height: 1.3em;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 30px;
}

.service-detail p{
	position: relative;
	font-size: 14px;
	line-height: 30px;
	color: #666666;
	font-weight: 400;
	margin-bottom: 30px;
}

.service-detail blockquote{
	position: relative;
	display: block;
	font-size: 14px;
	color: #666666;
	font-weight: 400;
	padding: 40px 40px 30px;
	line-height: 30px;
	background-color: #f3f3f3;
	border: 0;
	margin-bottom: 60px;
}

.service-detail blockquote cite{
	position: relative;
	display: block;
	text-align: right;
	font-style: normal;
	font-size: 15px;
	line-height: 1.2em;
	color: #fb4848;
	font-weight: 500;
	margin-top: 12px;
	font-family: "Rubik", sans-serif;
}

.service-detail .two-column{
	position: relative;
}

.service-detail .two-column .info-column{
	position: relative;
	margin-bottom: 60px;
}

.service-detail ul{
	position: relative;
	display: block;
}

.service-detail ul li{
	position: relative;
	padding-left: 30px;
	font-size: 14px;
	line-height: 25px;
	color: #666666;
	font-weight: 400;
	margin-bottom: 20px;
}

.service-detail ul li:before{
	position: absolute;
	left: 0;
	top: 0;
	width: 30px;
	text-align: left;
	font-size: 14px;
	line-height: 25px;
	color: #fb4848;
	font-weight: 900;
	font-family: "Font Awesome 5 Free";
	content: "\f00c" ;
}

.service-detail ul li a{
	color: #666666;
}

.service-detail .two-column .video-column{
	position: relative;
	display: block;
	margin-bottom: 70px;
}

.service-detail .video-column{
	position: relative;
	margin-bottom: 20px;
} 

.service-detail .video-column .inner-column{
	position: relative;
}	

.service-detail .video-column .image{
	position: relative;
	padding: 20px 20px;
	z-index: 9;
}

.service-detail .video-column .image img{
	display: block;
	width: 100%;
}

.service-detail .video-column .image:before{
	position: absolute;
	top: 0;
	left: 0;
	height: 260px;
	width: 310px;
	background-color: #ebebeb;
	content: "";
	z-index: -1;
}

.service-detail .video-column .image:after{
	position: absolute;
	right: 0;
	bottom: 0;
	height: 260px;
	width: 310px;
	content: "";
	border: 2px solid #ebebeb;
	z-index: -1;
}

.service-detail .video-column .image .link{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: rgba(34,34,34,.50)  padding-box content-box;
	padding: 20px 20px;
}

.service-detail .video-column .image .link span{
	position: absolute;
	top: 50%;
	left: 50%;
	height: 65px;
	width: 65px;
	text-align: center;
	line-height: 63px;
	border: 3px solid #ffffff;
	font-size: 26px;
	color: #ffffff;
	margin-left: -40px;
	margin-top: -40px;
	padding-left: 5px;
	background-color: #fb4848;
	border-radius: 50%;
}

.service-detail .video-column .image:hover .link span{
	-moz-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	transform: rotate(360deg);
	-moz-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.service-detail .video-column .image .link span:after{
	position: absolute;
    height: 75px;
    width: 75px;
    left: -8px;
    top: -8px;
	border:7px solid rgba(255,255,255,.50);
	border-radius: 50%;
}

.pricing-feature .sec-title{
	margin-bottom: 40px;
}

/*=== Feature Pricing Table ===*/

.pricing-feature{
	position: relative;
	display: block;
	background-color: #ffffff;
	margin-bottom: 40px;
}

.pricing-feature .table-outer{
	position:relative;
	width:100%;
}

.pricing-feature .feature-table{
	position:relative;
	width: 100%;
	min-width:870px;
}

.pricing-feature .feature-table thead{
    position: relative;
    width: 100%;
    background: #222222;
}

.pricing-feature .feature-table thead tr td{
	position: relative;
	font-size: 16px;
	color: #ffffff;
	font-weight:500;
    line-height: 30px;
	padding:15px 18px;
    text-transform: capitalize;
    font-family: "Rubik", sans-serif;
}

.pricing-feature .feature-table thead tr td:first-child{
	padding-left:40px;
}

.pricing-feature .feature-table tbody{
	border:1px solid #e8e8e8;
	border-top: 0;
}

.pricing-feature .table-outer tbody tr:nth-child(2n + 2){
	background-color:#eeeeee;
}

.pricing-feature .table-outer tbody tr td:first-child{
	padding-left:40px;
	font-weight: 700;
}

.pricing-feature .table-outer tbody tr td{
	color:#333333;
	font-size:14px;
	line-height: 30px;
	font-weight:400;
	text-align:left;
	padding:15px 18px;
}

/*Services Category*/

.service-cat-list{
	position:relative;
	margin-bottom: 45px;
}

.service-cat-list li{
	position:relative;
	margin-bottom: 2px;
}

.service-cat-list li a{
	position:relative;
	color:#333333;
	font-size:16px;
	line-height: 30px;
	font-weight:400;
	padding:14px 45px 14px 30px;
	display:block;
	background-color:#f3f3f3;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
}

.service-cat-list li a:before{
	position:absolute;
	content:'\f105';
	right: 45px;
	font-size: 18px;
	color: #333333;
	line-height: 30px;
	font-weight: 600;
	font-family: 'Font Awesome 5 Free';
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.service-cat-list li a:hover::before,
.service-cat-list li.active a::before{
	color: #ffffff;
}

.service-cat-list li.active a,
.service-cat-list li a:hover{
	color: #ffffff;
	background-color: #fb4848;
}

/*Brochure Box*/

.brochures {
	position: relative;
}

.brochures h3{
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 30px;
}

.brochure-box{
	position:relative;
}

.brochure-box .image-box{
	position:relative;
	margin-bottom: 40px;
}

.brochure-box .image-box img{
	position: relative;
	display: block;
	width: 100%;
} 

.brochure-box .link-box{
	position: relative;
	display: block;
}

.brochure-box .link-box a{
	display: block;
	font-size: 16px;
	color: #333333;
	line-height: 20px;
	margin-bottom: 30px;
	padding: 19px 35px;
	border:1px solid #cccccc;
	font-weight: 500;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.brochure-box .link-box a:hover{
	color: #ffffff;
	border-color: #222222;
	background-color: #222222;
}

.brochure-box .link-box a span{
	color: #fb4848;
	font-weight: 400;
	margin-right: 20px;
}

/*** 

====================================================================
				Why Us Section							
====================================================================

***/

.why-us-section{
	position: relative;
	padding: 100px 0;
}

.why-us-section .feature-tabs{
	position: relative;
}

.tabs-content .tab{
	display: none;
}

.tab.active-tab{
	display: block;
}

.feature-tabs .tab-btns{
	position: relative;
}

.feature-tabs .tab-btn{
	position: relative;
	background-color: #f2f2f2;
	margin-bottom: 10px;
	padding: 48px 40px;
	padding-left: 200px;
	border:1px solid transparent;
	cursor: pointer;
}	

.feature-tabs .tab-btn .icon-box{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 120px;
	background-color: #e2e2e2;
	padding: 20px 0;
	padding-right: 80px;
	text-align: right;
}

.feature-tabs .tab-btn .icon-box:before{
	position: absolute;
    right: -60px;
    top: 0;
    content: "";
    width: 0;
    height: 0;
    border-bottom: 190px solid #e2e2e2;
    border-right: 60px solid transparent;
}

.feature-tabs .tab-btn .icon-box span{
	position: absolute;
	top: 50%;
	font-size: 72px;
	line-height: 80px;
	margin-top: -40px;
	height: 80px;
}

.feature-tabs .tab-btn h3{
	position: relative;
	font-size: 20px;
	line-height: 28px;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 10px;
}

.feature-tabs .tab-btn p{
	position: relative;
	font-size: 14px;
	line-height: 28px;
	color: #666666;
	font-weight: 400;
}

.feature-tabs .tab-btn.active-btn{
	border:1px solid #fb4848;
	background-color:#fb4848;
}

.feature-tabs .tab-btn.active-btn .icon-box:before{
	border-bottom-color: #ffffff;
}

.feature-tabs .tab-btn.active-btn .icon-box{
	background-color: #ffffff;
}

.feature-tabs .tab-btn.active-btn p,
.feature-tabs .tab-btn.active-btn h3{
	color: #ffffff;
}

.feature-tabs .tabs-content{
	position: relative;
}

.feature-tabs .tabs-content .inner-box{
	position: relative;
	padding-top: 25px;
	padding-left: 20px;
}

.feature-tabs .tabs-content .title{
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 30px;
	color: #fb4848;
	margin-bottom: 10px;
}

.feature-tabs .tabs-content h2{
	position: relative;
	font-size: 30px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 30px;
}

.feature-tabs .tabs-content p{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 28px;
	color: #666666;
	font-weight: 400;
	margin-bottom: 35px;
}

.feature-tabs .tabs-content ul{
	margin-bottom: 40px;
}

.feature-tabs .tabs-content ul li{
	position: relative;
	font-size: 14px;
	line-height: 20px;
	color: #666666;
	margin-bottom: 10px;
	padding-left: 40px;
}

.feature-tabs .tabs-content ul li:before{
	position: absolute;
	left: 0;
	top: 0;
	line-height: 20px;
	font-size: 14px;
	color: #fb4848;
	font-weight: 900;
    content: "\f14a";
    font-family: "Font Awesome 5 Free";
}

.feature-tabs .tabs-content .theme-btn:hover{
	background-color: #222222;
}

/*** 

====================================================================
				Gallery Section
====================================================================

***/

.gallery-section{
	position: relative;
	padding: 115px 0 175px;
}

.mixitup-gallery .filters{
 	margin-bottom:70px;
 	text-align:center;
}

.mixitup-gallery .filters .filter-tabs{
 	position:relative;
 	display: inline-block;
}

.mixitup-gallery .filters li{
	position: relative;
	float: left;
	font-size: 16px;
	line-height: 18px;
	color: #222222;
	font-weight: 500;
	padding:10px 20px;
	border: 1px solid transparent;
	cursor: pointer;
 	background: #ffffff;
 	margin-right: 5px;
 	text-transform: uppercase;
	-moz-transition:all 300ms ease;
	transition: all 300ms ease;
	font-family: 'Rubik', sans-serif;
}

.mixitup-gallery .filters li.active,
.mixitup-gallery .filters li:hover{
	color: #fb4848;
	border: 1px solid #dddddd;
}

.gallery-item{
	position: relative;
	margin-bottom: 30px;
}

.gallery-item.mix{
	display: none;
}

.gallery-item .image-box{
	position: relative;
}

.gallery-item .image-box .image{
	position: relative;
}

.gallery-item .image-box .image img{
	position: relative;
	width: 100%;
	display: block;
}

.gallery-item .slide-item .image-box .overlay-box{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

.gallery-item .image-box .overlay-box:before{
	position: absolute;
	left: 50%;
	top: 50%;
	height: 0%;
	width: 0%;
	background-color: #222222;
	opacity: .80;
	content: "";
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.gallery-item:hover .image-box .overlay-box:before{
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

.gallery-item .icon-box{
	position: absolute;
	width: 100%;
	top: 50%;
	text-align: center;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.gallery-item .icon-box .link{
	position: relative;
	display: inline-block;
	margin-left: 15px;
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
}

.gallery-item .icon-box .icon{
	height: 40px;
	width: 40px;
	font-size: 14px;
	color: #ffffff;
	line-height: 38px;
	border: 1px solid #ffffff;
	background-color: transparent;
	text-align: center;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.gallery-item .icon-box .link:hover .icon{
	background-color: #fb4848;
	border: 1px solid #fb4848;
}

.gallery-item:hover .icon-box > .link{
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	-moz-transition-delay: 300ms;
	transition-delay: 300ms;
}

.gallery-item .icon-box h3{
	position: relative;
	display: block;
	margin-top: 40px;
	font-size: 18px;
	color: #ffffff;
	font-weight: 500;
	line-height: 1.3em;
	margin-bottom: 5px;
	opacity: 0;
	visibility: hidden;
	font-family: "Roboto", sans-serif;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.gallery-item .icon-box h3 a{
	color: #ffffff;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.gallery-item .icon-box h3 a:hover{
	color: #fb4848;
}

.gallery-item:hover h3{
	visibility: visible;
	opacity: 1;
	-moz-transition-delay: 300ms;
	     transition-delay: 300ms;
}

.gallery-section .btn-box{
	position: relative;
}

.gallery-section .btn-box .theme-btn{
	margin-top: 50px;
}

/*** 

====================================================================
					Project Section
====================================================================

***/

.project-section{
	position: relative;
	background-color: #f5f5f5;
	padding-top: 115px;
}

.project-tab .tab-btns{
	position: relative;
	text-align: center;
	margin-bottom: 65px;
}

.project-tab .tab-btns .tab-btn{
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 20px;
	color: #36404b;
	font-weight: 500;
	cursor: pointer;
 	margin: 0 20px;
 	text-transform: uppercase;
	-moz-transition:all 300ms ease;
	transition: all 300ms ease;
	font-family: 'Rubik', sans-serif;
}

.project-tab .tab-btns .tab-btn.active-btn,
.project-tab .tab-btns .tab-btn:hover{
	color: #fb4848;
}

.project-tab .tabs-content .gallery-item{
	-moz-transform: scale(.5);
	-ms-transform: scale(.5);
	transform: scale(.5);
	margin-bottom: 5px;
	-moz-transition:all 300ms ease;
	transition: all 300ms ease;
}

.project-tab .tabs-content .active-tab .gallery-item{
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

/*** 

====================================================================
				Project Detail Section
====================================================================

***/

.project-detail{
	position: relative;
	padding: 120px 0 150px;
}

.project-detail .upper-box{
	position: relative;
}

.project-detail .upper-box .image-column{
	position: relative;
	margin-bottom: 40px;
}

.project-detail .upper-box .image-box{
	position: relative;
}

.project-detail .upper-box .image-box img{
	display: block;
	width: 100%;
}

/*=== Price List ===*/

.project-detail .price-column{
	position: relative;
	margin-bottom: 40px;
}

.project-detail .price-list{
	position: relative;
}

.project-detail .price-list li{
	position: relative;
	font-size: 14px;
	color: #333333;
	font-weight: 400;
	line-height: 24px;
	padding: 15px 20px;
	min-width: 370px;
	width: 100%;
} 

.project-detail .price-list li:nth-child(odd){
	background-color: #f5f5f5;
}

.project-detail .price-list li span{
	min-width: 150px;
	text-align:left;
	float: right;
	color: #666666;
}

.project-detail .lower-content{
	position: relative;
}

.project-detail h2{
	position: relative;
	font-size: 30px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 30px;
}

.project-detail h3{
	position: relative;
	font-size: 24px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 30px;
}

.project-detail p{
	position: relative;
	font-size: 14px;
	line-height: 30px;
	color: #666666;
	font-weight: 400;
	margin-bottom: 30px;
}

.project-detail .two-column{
	position: relative;
	margin-bottom: 60px;
}

.project-detail .two-column .image-column{
	position: relative;
}

.project-detail .two-column .image-column a{
	display: block;
}

.project-detail .two-column .image-column img{
	width: 100%;
	height: auto;
	display: block;
}

.project-detail .check-list{
	position: relative;
	display: block;
}

.project-detail .check-list li{
	position: relative;
	padding-left: 30px;
	font-size: 14px;
	line-height: 25px;
	color: #666666;
	font-weight: 400;
	margin-bottom: 25px;
}

.project-detail .check-list li:before{
	position: absolute;
	left: 0;
	top: 0;
	width: 30px;
	text-align: left;
	font-size: 14px;
	line-height: 25px;
	color: #fb4848;
	font-weight: 900;
	font-family: "Font Awesome 5 Free";
	content: "\f00c" ;
}

.project-detail .check-list li a{
	color: #666666;
}

/*=== Info List ===*/

.project-detail .project-info{
	position: relative;
	margin-bottom: 20px;
}

.project-info .column{
	position: relative;
	margin-bottom: 20px;
}

.project-info .info{
	position: relative;
	font-size: 16px;
	color: #777777;
	font-weight: 400;
	line-height: 20px;
	padding: 14px 20px;
	padding-left: 75px;
	border: 1px solid #bbbbbb;
	margin-right: 30px;
	width: 100%;
}

.project-info .info a{
	color: #777777;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.project-info .info a:hover{
	color: #fb4848;
}

.project-info .info strong{
	position: relative;
	font-weight: 700;
	color: #fb4848;
	margin-right: 15px;
}

.project-info .info .icon {
    position: absolute;
    left: -1px;
    top: -1px;
    width: 50px;
    height: 51px;
    color: #ffffff;
    bottom: -1px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    background-color: #222222;
}

.project-info .social-icons{
	position: relative;
	padding: 10px 0;
}

.project-info .social-icons .follow{
	position:relative;
	font-size:20px;
	font-weight:500;
	color:#fb4848;
	font-family: "Rubik", sans-serif;
}

.project-info .social-icons a{
	color:#36404b;
	font-size:18px;
	margin-left:17px;
	line-height: 30px;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.project-info .social-icons a:hover{
    color: #fb4848;
}

/*** 

====================================================================
					Video And Faq's
====================================================================

***/

.video-and-faqs{
	position: relative;
	padding: 105px 0 100px;
}

.video-and-faqs .video-column{
	position: relative;
	margin-bottom: 20px;
} 

.video-and-faqs .video-column .inner-column{
	position: relative;
}

.video-and-faqs .video-column .image{
	position: relative;
	padding: 25px 25px;
	z-index: 9;
}

.video-and-faqs .video-column .image img{
	display: block;
	width: 100%;
}

.video-and-faqs .video-column .image:before{
	position: absolute;
	top: 0;
	left: 0;
	height: 320px;
	width: 370px;
	background-color: #ebebeb;
	content: "";
	z-index: -1;
}

.video-and-faqs .video-column .image:after{
	position: absolute;
	right: 0;
	bottom: 0;
	height: 320px;
	width: 370px;
	content: "";
	border: 2px solid #ebebeb;
	z-index: -1;
}

.video-and-faqs .video-column .image .link{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: rgba(34,34,34,.50)  padding-box content-box;
	padding: 25px 25px;
}

.video-and-faqs .video-column .image .link span{
	position: absolute;
	top: 50%;
	left: 50%;
	height: 80px;
	width: 80px;
	text-align: center;
	line-height: 74px;
	border: 3px solid #ffffff;
	font-size: 34px;
	color: #ffffff;
	margin-left: -40px;
	margin-top: -40px;
	padding-left: 5px;
	background-color: #fb4848;
	border-radius: 50%;
}

.video-and-faqs .video-column .image:hover .link span{
	-moz-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	transform: rotate(360deg);
	-moz-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.video-and-faqs .video-column .image .link span:after{
	position: absolute;
	height: 96px;
	width: 96px;
	left: -11px;
	top: -11px;
	content:"";
	border:8px solid rgba(255,255,255,.50);
	border-radius: 50%;
}

.video-and-faqs .faq-column .inner-column{
	position: relative;
	padding-left: 25px;
}

.video-and-faqs .faq-column .sec-title{
	margin-bottom: 20px;
	top: -10px;
}

/*===================================\
		Accordion Style
====================================*/

.accordion-box{
	position:relative;
}

.accordion-box .block{
	position:relative;
}

.accordion-box .block .acc-btn{
	position:relative;
	font-size:16px;
	line-height:25px;
	color:#36404b;
	font-weight: 500;
	cursor:pointer;
	overflow:hidden;
	z-index:1;
	padding: 20px 15px 19px 45px;
	border-bottom: 1px solid #eeeeee;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.accordion-box .block .acc-btn .icon{
	position: absolute;
	left: 0;
	top: 20px;
	height: 26px;
	width: 26px;
	line-height: 26px;
	font-size: 12px;
	color: #fb4848;
	border-radius: 50%;
	border: 1px solid #eeeeee;
	text-align: center;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.accordion-box .block .acc-btn.active .icon:before{
	content: "\f068";
}

.accordion-box .block .acc-btn.active{
	color: #fb4848;
	border-bottom: 0;
	padding-bottom: 10px;
}

.accordion-box .block .acc-content{
	position:relative;
	display:none;	
}

.accordion-box .block .acc-content.current{
	display:block;	
}

.accordion-box .block .content{
	position:relative;
	padding: 0 20px 10px; 
	min-height: 115px;
	padding-left: 45px;
	border-bottom: 1px solid #eeeeee;
}	

.accordion-box .block .content p{
	position: relative;
	font-size: 14px;
	line-height: 30px;
	color: #666666;
}

/*** 

====================================================================
				Subscribe Section
====================================================================

***/

.subscribe-section{
	position: relative;
	margin: 0;
	background-color: #535f9e;
}

.subscribe-section .inner-container{
	position: relative;
	background-color: #535f9e !important;
	outline: 1px solid #FFFFFF;
	outline-offset: -22px;
	padding: 50px 70px;
}

.subscribe-section h3 {
	position: relative;
	padding: 0;
	font-size: 20px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	font-family: "Rubik", sans-serif;
}

.subscribe-section h4 {
	position: relative;
	float: left;
	padding: 0;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	font-family: "Rubik", sans-serif;
}

.subscribe-section .call-btn{
	position: relative;
	float: right;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-weight: 400;
	padding: 14px 35px;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background: #2eacdb;
	text-transform: uppercase;
}

.subscribe-section .call-btn:hover{
	background-color: #ffffff;
	color: #fb4848;
}

/*** 

====================================================================
				News Section
====================================================================

***/

.news-section{
	position: relative;
	padding: 170px 0 100px;
	background-color: #222222;
	background-repeat: no-repeat;
	background-position: center;
}

.news-section .title-box{
	position: relative;
	display: block;
	padding-bottom: 35px;
	margin-bottom: 55px;
}

.news-section .title-box:before,
.news-section .title-box:after{
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: #dddddd;
	content: "";
	left: 0;
	bottom: 0;
}

.news-section .title-box:before{
	bottom: 5px;
}

.news-section .title-box h2{
	position: relative;
	font-size: 30px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 500;
	text-transform: uppercase;
}

.news-block{
	position: relative;
}

.news-block .inner-box{
	position: relative;
}

.news-block .image-box{
	position: relative;
	float: left;
}

.news-block .image-box .label{
	position: absolute;
	left: 30px;
	top: 0;
	width: 70px;
	padding: 0;
	text-align: center;
	font-family: "Rubik", sans-serif;
}

.news-block .label .date{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 20px;
	color: #36404b;
	font-weight: 600;
	background-color: #f2f2f2;
	height: 60px;
	width: 100%;
	padding: 10px 0;
	text-transform: uppercase;
}

.news-block .label .date span{
	position: relative;
	display: block;
	font-size: 24px;
	line-height: 1em;
	color: #36404b;
	font-weight: 600;
}

.news-block .label .likes{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 20px;
	padding: 5px 0;
	color: #ffffff;
	font-weight: 500;
	background-color: #fb4848;
}

.news-block .label .likes i{
	margin-right: 3px;
}

.news-block .image-box .image{
	position: relative;
}

.news-block .image-box a{
	display: block;
}

.news-block .image-box img {
	width: 100%;
	height: auto;
	display: block;
}

.news-block .content-box{
	position: absolute;
	top: 50%;
	width: 330px;
	right: 0; 
	background-color: #ffffff;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	padding: 30px 20px 25px 30px;
}

.news-block .content-box h3{
	position: relative;
	font-size: 22px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 10px;
}

.news-block .content-box h3 a{
	color:#36404b;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block .content-box h3 a:hover{
	color: #fb4848;
}

.news-block .content-box .info{
	position: relative;
	display: block;
	margin-bottom: 10px;
}

.news-block .content-box .info li{
	position: relative;
	display: inline-block;
	font-size: 13px;
	line-height: 15px;
	color: #222222;
	font-weight: 500;
	padding-right: 15px;
	margin-right: 15px;
	border-right: 1px solid #222222;
}

.news-block .content-box .info li:last-child{
	margin-right: 0;
	border-right: 0;
	padding-right: 0;
}

.news-block .content-box .info li a{
	color: #222222;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block .content-box .info li a:hover{
	color: #fb4848;
}

.news-block .content-box .info li i{
	display: inline-block;
	margin-right: 10px;
}

.news-block .content-box p{
	position: relative;
	font-size: 14px;
	line-height: 30px;
	color: #666666;
	margin-bottom: 15px;
}

.news-block .content-box .read-more{
	position: relative;
	font-size: 14px;
	line-height: 20px;
	color: #fb4848;
	font-weight: 600;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block .content-box .read-more:hover{
	color: #222222;
}

.news-carousel .owl-nav{
	display: block;
}

.news-carousel .owl-nav{
    position: absolute;
    right: 20px;
    top: -115px;
    z-index: 99;
}

.news-carousel .owl-prev, 
.news-carousel .owl-next {
    position: absolute;
    height: 35px;
    width: 35px;
    right: 0;
    line-height: 35px;
    text-align: center;
    font-size: 16px;
	color: #222222; 
	background: #ffffff;
	-moz-transition:all 300ms ease;
 transition: all 300ms ease;
}

.news-carousel .owl-prev{
	left: -80px;
}

.news-carousel .owl-prev:hover, 
.news-carousel .owl-next:hover{
	background-color: #fb4848;
	color: #ffffff;
}

/*** 

====================================================================
				Team Section
====================================================================

***/

.team-section{
	position: relative;
	padding: 100px 0px 80px;
}

.team-section .team-block{
	position: relative;
	margin-bottom: 40px;
}

.team-block .inner-box{
	position: relative;
	text-align: center;
}

.team-block .image-box:before{
	position: absolute;
	left: 0;
	top: 100px;
	height: 100%;
	width: 100%;
	border: 6px solid #fb4848;
	border-bottom: 6px solid transparent;
	content:'';
	z-index: -1;
	-moz-transform: skewY(10deg);
	-ms-transform: skewY(10deg);
	transform: skewY(10deg);
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.team-block .inner-box:hover .image-box:before{
	-moz-transform: skew(0deg);
	-ms-transform: skew(0deg);
	transform: skew(0deg);
}


.team-block .image-box{
	position: relative;
	display: block;
	overflow: hidden;
}

.team-block .image-box img{
	max-width: 100%;
	height: auto;
}

.team-block .social-icon{
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: #fb4848;
	width: 50px;
	padding: 10px 0;
}

.team-block .social-icon li{
	display: block;
	text-align: center;
}

.team-block .social-icon li a{
	display: block;
	font-size: 14px;
	color: #ffffff;
	line-height: 30px;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.team-block .social-icon li a:hover{
	color: #36404b;
}

.team-block .info-box{
	position: relative;
	display: block;
	text-align: center;
	border-left: 6px solid #fb4848;
	border-right: 6px solid #fb4848;
	background-color: #222222;
} 

.team-block .info-box .inner-box{
	position: relative;
	padding: 20px 0;
	display: block;
	background-color: #222222;
	bottom: -5px;
}

.team-block .info-box .name{
	position: relative;
	font-size: 18px;
	font-weight: 500;
	color: #ffffff;
	line-height: 1.2em;
	text-transform: uppercase;
}

.team-block .info-box .name a{
	color: #ffffff;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.team-block .info-box .name a:hover{
	color: #fb4848;
}

.team-block .info-box .designation{
	position: relative;
	display: inline-block;
	font-size: 14px;
	color: #ffffff;
	font-weight: 400;
}

/*============================
	Team Block Last Child 2
=============================*/

.team-block:nth-child(3n + 2) .image-box:before{
	top: 130px;
	-moz-transform: skewY(0deg);
	 -ms-transform: skewY(0deg);
	     transform: skewY(0deg);
}

.team-block:nth-child(3n + 2) .social-icon{
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	display: inline-block;
	max-width: 180px;
	width: 100%;
}

.team-block:nth-child(3n + 2) .social-icon li{
	display: inline-block;
	padding: 0 8px;
}

.team-block:nth-child(3n + 2) .image-box:before{
	top: 130px;
	-moz-transform: skewY(0deg);
	 -ms-transform: skewY(0deg);
	     transform: skewY(0deg);
}

.team-block:nth-child(3n + 2) .social-icon{
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	display: inline-block;
	max-width: 180px;
	width: 100%;
}

.team-block:nth-child(3n + 2) .social-icon li{
	display: inline-block;
	padding: 0 8px;
}

/*============================
	Team Block Last Child 3
=============================*/

.team-block:nth-child(3n + 3) .image-box:before{
	top: 100px;
	-moz-transform: skewY(-10deg);
	 -ms-transform: skewY(-10deg);
	     transform: skewY(-10deg);
}

.team-block:nth-child(3n + 3) .social-icon{
	bottom: 0;
	left: auto;
	right: 0;
}

/*** 

====================================================================
				Testimonial Section
====================================================================

***/

.testimonial-section{
	position: relative;
	padding: 100px 0;
}

.testimonial-section .testimonial-carousel{
	position: relative;
	padding-bottom: 105px;
}

.testimonial-block{
	position: relative;
	display: block;
	padding: 25px 50px;
	background-color: #222222;
	max-width: 445px;
	width: 100%;
	text-align: right;
}

.testimonial-block:before{
	position: absolute;
	left:35px;
	top: 0;
	height: 100%;
	width: 100%;
	-moz-transform: skewX(20deg);
	-ms-transform: skewX(20deg);
	transform: skewX(20deg);
	content: "";
	border-right: 7px solid #fb4848;
	background-color: #222222;
}

.testimonial-block .thumb{
	position: absolute;
	height: 90px;
	width: 90px;
	right: 0;
	top: 30px;
	margin-right: -75px;
	border: 3px solid #fb4848;
}

.testimonial-block .thumb img{
	display: block;
	height: 100%;
	width: 100%;
}

.testimonial-block p{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 24px;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 10px;
}

.testimonial-block .name{
	position: relative;
	font-size: 14px;
	line-height: 24px;
	color: #ffffff;
	font-weight: 400;
	text-transform: uppercase;
}

.testimonial-block .designation{
	position: relative;
	display: inline-block;
	font-size: 14px;
	color: #f14848;
	text-transform: capitalize;
}

.testimonial-block .icon{
	position: absolute;
	left: 0;
	bottom: 0;
	height:35px;
	width: 35px;
	line-height: 35px;
	text-align: center;
	background-color: #fb4848;
	color: #ffffff;
	padding-left: 10px;
}

.testimonial-block .icon:before{
	display: inline-block;
	-moz-transform: scaleY(-1);
	-ms-transform: scaleY(-1);
	transform: scaleY(-1);
}

.testimonial-block .icon:after{
	position: absolute;
	right: -17px;
	width: 0;
	height: 0;
	border-bottom: 35px solid #fb4848;
	border-right: 17px solid transparent;
	content: "";
}

.testimonial-block.even{
	float: right;
	text-align: left;
}

.testimonial-block.even:before{
	left: auto;
	right:35px;
	-moz-transform: skewX(-20deg);
	-ms-transform: skewX(-20deg);
	transform: skewX(-20deg);
	border-left: 7px solid #fb4848;
	border-right: 0;
}

.testimonial-block.even .thumb{
	right: auto;
	left: 0;
	margin-left: -75px;
}

.testimonial-block.even .icon{
	position: absolute;
	left: auto;
	right: 0;
	text-align: center;
	padding-left: 0px;
	padding-right: 10px;
}

.testimonial-block.even .icon:before{
	-moz-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
}

.testimonial-block.even .icon:after{
	position: absolute;
	left: -17px;
	right: auto;
	width: 0;
	height: 0;
	border-right: 0;
	border-bottom: 35px solid #fb4848;
	border-left: 17px solid transparent;
	content: "";
}

.testimonial-carousel .owl-nav{
    position: absolute;
    bottom: 0;
    z-index: 99;
    text-align: center;
    width: 100%;
}

.testimonial-carousel .owl-prev, 
.testimonial-carousel .owl-next {
    position: relative;
    display: inline-block;
    height: 35px;
    width: 35px;
    margin: 0 5px;
    line-height: 35px;
    text-align: center;
    font-size: 16px;
	color: #222222; 
	background: #ffffff;
	border: 1px solid #222222;
	-moz-transition:all 300ms ease;
 transition: all 300ms ease;
}

.testimonial-carousel .owl-prev:hover, 
.testimonial-carousel .owl-next:hover{
	background-color: #fb4848;
	border-color: #fb4848;
	color: #ffffff;
}

/*** 

====================================================================
				Testimonial Section Two
====================================================================

***/

.testi-sec-two{
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 110px 0 120px;
}

.testi-sec-two .auto-container{
	position: relative;
}

.testi-sec-two:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: #ffffff;
	opacity: .93;
	content: "";
}

.testimonial-carousel-two{
	position: relative;
	max-width: 870px;
}

.testimonial-block-two{
	position: relative;
	padding-left: 320px;
	min-height: 290px;
}

.testimonial-block-two .image-box{
	position: absolute;
	left: 0;
	top: 0;
	padding: 10px 10px;
	z-index: 99;
}

.testimonial-block-two .image-box img{
	display: block;
	width: 100%;
	height: auto;
}

.testimonial-block-two .image-box:before{
	position: absolute;
	right: 0;
	top: 0;
	height: 80px;
	width: 80px;
	background-color: #222222;
	content: "";
	z-index: -1;
}

.testimonial-block-two .image-box:after{
	position: absolute;
	left: 0;
	bottom: 0;
	height: 80px;
	width: 80px;
	background-color: #fb4848;
	content: "";
	z-index: -1;
}

.testimonial-block-two .info-box{
	position: relative;
	padding-top: 10px;
	margin-bottom: 30px;
}

.testimonial-block-two .info-box .name{
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 30px;
	color: #2e3760;
	font-weight: 500;
}

.testimonial-block-two .info-box .designation{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 20px;
	margin-top: 10px;
	color: #666666;
	font-weight: 400;
}

.testimonial-block-two .info-box .rating{
	position: absolute;
	right: 0;
	top: 35px;
}

.testimonial-block-two .info-box .rating i{
	position: relative;
	display: inline-block;
	font-size: 15px;
	line-height: 20px;
	color: #fb4848;
	font-weight: 900;
}

.testimonial-block-two .text{
	position: relative;
}

.testimonial-block-two .text i{
	position: relative;
	display: inline-block;
	margin-right: 15px;
	font-size: 16px;
	line-height: 30px;
	color: #cccccc;
}

.testimonial-block-two .text p{
	position: relative;
	display: inline;
	font-size: 15px;
	line-height: 30px;
	color: #666666;
	font-weight: 400;
}

.testimonial-carousel-two .owl-nav{
	display: block;
}

.testimonial-carousel-two .owl-nav{
    position: absolute;
    left: 320px;
    bottom: 50px;
    width: 75px;
    z-index: 99;
}

.testimonial-carousel-two .owl-nav:before{
	position: absolute;
	left: 50%;
	top: 0;
	content: "/";
	font-size: 24px;
	line-height: 30px;
	color: #fb4848;
	font-weight: 600;
	display: inline-block;
	margin-left: -4px;
}

.testimonial-carousel-two .owl-prev, 
.testimonial-carousel-two .owl-next {
    position: absolute;
    height: 30px;
    width: 30px;
    left: 0;
    line-height: 30px;
    text-align: center;
    font-size: 24px;
	color: #36404b;
	-moz-transition:all 300ms ease;
 transition: all 300ms ease;
}

.testimonial-carousel-two .owl-next{
	right: 0px;
	left: auto;
}

.testimonial-carousel-two .owl-prev:hover, 
.testimonial-carousel-two .owl-next:hover{
	color: #fb4848;
}

.testi-sec-two .side-img{
	position: absolute;
    left: 0;
    max-width: 1170px;
    margin: 0 auto;
    right: 0;
    bottom: 0;

}

.testi-sec-two .side-img img{
    position: relative;
    left: 100%;
    margin-left: -270px;
    bottom: -60px;
}

/*** 

====================================================================
			Testimonial And BLog
====================================================================

***/

.testimonial-and-blog{
	position: relative;
	padding: 115px 0 80px;
}

.testimonial-and-blog .testimonial-column{
	position: relative;
	margin-bottom: 40px;
}

.testimonial-carousel-three{
	position: relative;
	padding:50px 40px 30px;
	background-color: #f2f2f2;
}

.testimonial-block-three{
	position: relative;
}

.testimonial-block-three .info-box{
	position: relative;
	padding-left: 90px;
	min-height: 70px;
	margin-bottom: 50px;
	padding-top: 8px;
}

.testimonial-block-three .info-box .thumb{
	position: absolute;
	left: 0;
	top: 0;
	height: 70px;
	width: 70px;
}

.testimonial-block-three .info-box .thumb img{
	width: 100%;
	height: auto;
}

.testimonial-block-three .info-box .name{
	position: relative;
	font-size: 20px;
	line-height: 30px;
	color: #2e3760;
	font-weight: 500;
}

.testimonial-block-three .info-box .designation{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 30px;
	color: #666666;
	font-weight: 400;
}

.testimonial-block-three .text{
	position: relative;
	display: block;
	margin-bottom: 50px;
}

.testimonial-block-three .text i{
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 30px;
	color: #cccccc;
}

.testimonial-block-three .text p{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 34px;
	color: #666666;
	font-weight: 400;
}

.testimonial-block-three .rating{
	position: relative;
	display: block;
	text-align: right;
	padding: 10px 0;
}

.testimonial-block-three .rating span{
	position: relative;
	display: inline-block;
	font-size: 15px;
	line-height: 20px;
	color: #fb4848;
}

.testimonial-carousel-three .owl-nav{
	display: none;
}

.testimonial-carousel-three .owl-dots{
	position: absolute;
    left: 0;
    bottom: 30px;
    width: 130px;
    background-color: #222222;
    text-align: center;
    padding: 6px 0 7px;
    padding-left: 20px;
}

.testimonial-carousel-three .owl-dots:before{
	position: absolute;
    right: -20px;
    top: 0;
    content: "";
    width: 0;
    height: 0;
    border-bottom: 40px solid #222222;
    border-right: 20px solid transparent;
}

.testimonial-carousel-three .owl-dots .owl-dot{
	position:relative;
	display: inline-block;
	height: 8px;
	width: 8px;
	margin: 0 7px;
	border:1px solid #cbcbcb;
	-moz-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.testimonial-carousel-three .owl-dots .owl-dot.active,
.testimonial-carousel-three .owl-dots .owl-dot:hover{
	border:1px solid #eeeeee;
	background-color: #fb4848;
}

.news-block-two{
	position: relative;
	margin-bottom: 40px;
}

.news-block-two .inner-box{
	position: relative;
	overflow: hidden;
}

.news-block-two .image-box {
	position: relative;
	display: block;
}

.news-block-two .image-box img{
	position: relative;
	display: block;
	width: 100%;
	height: auto;
}

.news-block-two .image-box a{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block-two .image-box a:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #222222;
	opacity: .60;
	content: "";

}

.news-block-two .inner-box:hover .image-box a{
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.plus-icon{
	height: 50px;
	width: 50px;
	background-image: url(../img/icons/plus.png);
	background-position: center;
	background-repeat: no-repeat;
}

.news-block-two .image-box a .plus-icon{
	position: absolute;
	left: 50%;
	top: 50%;
	height: 50px;
	width: 50px;
	margin-top: -25px;
	margin-left: -25px;
}

.news-block-two .lower-content{
	position: relative;
}

.news-block-two .lower-content .info-box{
	position: relative;
	display: block;
	background-color: #f2f2f2;
	padding: 20px 0;
	padding-left: 25px;
	margin-bottom: 40px;
}

.news-block-two .info-box li{
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 20px;
	color: #222222;
	font-weight: 400;
	padding-right: 11px;
	margin-right: 10px;
}

.news-block-two .info-box li a{
	color: #222222;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block-two .info-box li a:hover{
	color: #fb4848;
}

.news-block-two .info-box li:before{
	position: absolute;
	right: 0;
	top: 3px;
	height: 14px;
	width: 1px;
	background-color: #4f4f4f;
	content: "";
}

.news-block-two .info-box li:last-child:before{
	display: none;
}

.news-block-two .info-box li:last-child{
	margin-right: 0;
	padding-right: 0;
}

.news-block-two .info-box li i{
	margin-right: 10px;
	font-weight: 400;
}

.news-block-two .lower-content .content{
	position:relative;
	padding-left: 100px;
}

.news-block-two .content h3{
	position: relative;
	display: block;
	font-size: 22px;
	line-height: 30px;
	top: -5px;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 15px;
}

.news-block-two .content h3 a{
	color: #36404b;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block-two .content h3 a:hover{
	color: #fb4848;
}

.news-block-two .content p{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 30px;
	color: #666666;
	font-weight: 400;
	margin-bottom: 27px;
}

.news-block-two .content .read-more{
	position: relative;
	display: block;
	text-align: center;
	font-size:  14px;
	line-height: 20px;
	color: #36404b;
	font-weight: 500;
	padding: 10px 25px;
	border:1px solid #cbcbcb;
	font-family: "Rubik", sans-serif;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block-two .content .read-more:hover{
	color: #fb4848;
}

.news-block-two .date-and-like{
	position: absolute;
	left: 0;
	top: 0;
	width: 70px; 
	z-index: 99;
	text-align: center;
}

.news-block-two .date-and-like .date{
	position: relative;
	display: block;
	background-color: #f2f2f2;
    padding-top: 9px;
    padding-bottom: 7px;
}
.news-block-two .date-and-like .date p{
    margin: 0;
    font-size: 10px;
    padding: 0;
    line-height: 0.5;
}

.news-block-two .date-and-like .date h4{
	position: relative;
	font-size: 24px;
	line-height: 1.2em;
	color: #222222;
	font-weight: 500;
}

.news-block-two .date-and-like .date span{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 1.2em;
	color: #222222;
	font-weight: 500;
	text-transform: uppercase;
	font-family: "Rubik", sans-serif;
}

.news-block-two .date-and-like .like{
	position: relative;
	background-color: #fb4848;
	color: #ffffff;
	font-size: 14px;
	line-height: 20px;
	padding: 5px 0;
}

.news-block-two .date-and-like .like span{
	margin-right: 7px;
	font-size: 14px;
	font-weight: 400;
}

.blog-list .news-block-two{
	margin-bottom: 60px;
}

.blog-list .news-block-two .content h3{
	margin-bottom: 10px;
}

.blog-list .news-block-two .content p{
	font-weight: 500;
}

.blog-list .styled-pagination{
	margin-top: 100px;
}

/*** 

====================================================================
				Blog Detail
====================================================================

***/

.blog-detail .news-block-two{
	margin-bottom: 20px;
}

.blog-detail .news-block-two .date-and-like{
	left: 30px;
	top: 0;
}

.blog-detail h3{
	position: relative;
	display: block;
	font-size: 30px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 20px;
}

.blog-detail p{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 30px;
	color: #666666;
	font-weight: 400;
	margin-bottom: 30px;
}

.blog-detail blockquote{
	position: relative;
	display: block;
	font-size: 16px;
	line-height: 30px;
	color: #666666;
	font-weight: 400;
	text-align: center;
	font-style: italic;
	border: 1px solid #fb4848;
	margin-bottom: 30px;
	padding: 29px 20px;
	font-family: "Roboto", sans-serif;
}

.blog-detail .post-share-options{
	position: relative;
	display: block;
	padding: 20px 0;
	border-top: 1px solid #dddddd;
}

.blog-detail .post-share-options .social-links{
	position: relative;
	float: left;
}

.post-share-options h4{
	position: relative;
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
	color: #fb4848;
	display: inline-block;
	margin-right: 10px;
}

.post-share-options .social-links ul{
	position: relative;
	display: inline-block;
}

.post-share-options .social-links li{
	position: relative;
	display: inline-block;
	margin-left: 20px;
}

.post-share-options .social-links li a{
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 20px;
	color: #777777;
	font-weight: 400;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.post-share-options .social-links li a:hover{
	color: #fb4848;
}

.post-share-options .next-prev{
	position: relative;
	float: right;
}

.post-share-options .next-prev a{
	position: relative;
	display: inline-block;
	font-size: 18px;
	font-weight: 500;
	color: #222222;
	padding-right: 20px;
	margin-right: 20px;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.post-share-options .next-prev a:last-child{
	padding-right: 0;
	margin-right: 0;
}

.post-share-options .next-prev a:last-child:before{
	display: none;
}

.post-share-options .next-prev a:before{
	position: absolute;
	right: 0;
	top: 5px;
	width: 1px;
	height: 15px;
	background-color: #1c63b8;
	content: "";
}

.post-share-options .next-prev a:hover{
	color: #fb4848;
}

.post-share-options .next-prev .prev i{
	margin-right: 10px;
}

.post-share-options .next-prev .next i{
	margin-left: 10px;
}

/*** 

====================================================================
			Styled Pagination
====================================================================

***/

.styled-pagination{
	position: relative;
}

.styled-pagination ul{
	position:relative;
	display: inline-block;
}

.styled-pagination li{
	position:relative;	
	display: inline-block;
	margin-right: 15px;
}

.styled-pagination li a{
	position:relative;
	display: block;
	height:40px;
	width: 40px;
	text-align: center;
	line-height:20px;
	padding:9px 5px 7px;
	font-size:16px;
	background:#ffffff;
	color:#36404b;
	font-weight:500;
	border:2px solid #dddddd;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.styled-pagination li a:hover,
.styled-pagination li a.active{
	color:#fb4848;	
	border:2px solid #fb4848;
}

.styled-pagination li a.next,
.styled-pagination li a.prev{
	background: #222222;
	color: #ffffff;
	border:2px solid #222222;	
}

.styled-pagination li a.next:hover,
.styled-pagination li a.prev:hover{
	background: #fb4848;
	color: #ffffff;
	border:2px solid #fb4848;
}	


/***

==================================================================
				Team Page Section
==================================================================

***/

.team-page-section{
	position: relative;
	padding: 120px 0 160px;
}

.team-block-two{
	position: relative;
	margin-bottom: 40px;
}

.team-block-two .inner-box{
	position: relative;
	overflow: hidden;
}

.team-block-two .image-box{
	position: relative;
}

.team-block-two .image-box img{
	display: block;
	width: 100%;
	height: auto;
}

.team-block-two .image-box:after {
    height: 100%;
    content: "";
    display: block;
    right: -100%;
    opacity: 0;
    position: absolute;
    top: 0;
    -moz-transition: all 0.6s ease 0s;
    transition: all 0.6s ease 0s;
    width: 100%;
    background-color: #222222;
}

.team-block-two .inner-box:hover .image-box:after {
    right: 50%;
    opacity: .70;
}

.team-block-two .image-box:before {
    height: 100%;
    content: "";
    display: block;
    left: -100%;
    opacity: 0;
    position: absolute;
    top: 0;
    -moz-transition: all 0.6s ease 0s;
    transition: all 0.6s ease 0s;
    width: 100%;
    background-color: #222222;
}

.team-block-two .inner-box:hover .image-box:before {
    left: 50%;
    opacity: .70;
}

.team-block-two .overlay-box{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	top: 50%;
	z-index: 99;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.team-block-two .inner-box:hover .overlay-box{
	opacity: 1;
	visibility: visible;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	-moz-transition-delay: 600ms;
	transition-delay: 600ms;
}

.team-block-two .social-links li{
	position: relative;
	display: inline-block;
	margin: 0 7px;

}

.team-block-two .social-links li a{
	display: block;
	font-size: 16px;
	line-height: 40px;
	color: #ffffff;
	font-weight: 400;
	height: 40px;
	width: 40px;
	text-align: center;
	border:1px solid #ffffff;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.team-block-two .social-links li a:hover{
	background-color: #fb4848;
}

.team-block-two .overlay-box .designation{
	position: relative;
	display: inline-block;
	font-size: 14px;
	color: #ffffff;
	font-weight: 400;
	margin-top: 30px;
}

.team-block-two .caption-box{
	position: relative;
	background-color: #222222;
	padding: 20px 0;
	text-align: center;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.team-block-two .caption-box .name{
	position: relative;
	display: block;
	font-size: 16px;
	color: #ffffff;
	font-weight: 400;
}

.team-block-two .caption-box .name a{
	color: #ffffff;
}

.team-block-two .inner-box:hover .caption-box{
	background-color: #fb4848;
}

/*** 

====================================================================
				Pricing Section
====================================================================

***/

.pricing-section{
	position: relative;
	padding: 115px 0 80px;
}

.pricing-table{
	position: relative;
	margin-bottom: 50px;
}

.pricing-table .inner-box{
	position: relative;
	padding: 45px 0 50px;
	border: 2px solid #dddddd;
	text-align: center;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.pricing-table.tagged .inner-box,
.pricing-table .inner-box:hover{
	border-color: #fb4848;
}

.pricing-table .title{
	position: relative;
	padding-bottom: 35px;
}

.pricing-table .title h3{
	position: relative;
	display: block;
	font-size: 36px;
	line-height: 1.2em;
	color: #fb4848;
	font-weight: 500;
	text-transform: uppercase;
}

.pricing-table .title p{
	position: relative;
	font-size: 20px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	margin-top: 8px;
}

.pricing-table .title .category{
	position: absolute;
	right: 0;
	bottom: 0;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	padding: 0 10px;
	background-color: #222222;
	text-transform: uppercase;
}

.pricing-table .title .category:before{
	position: absolute;
    left: -17px;
    width: 0;
    height: 0;
    border-bottom: 30px solid #222222;
    border-left: 17px solid transparent;
    content: "";
}

.pricing-table .table-content{
	position: relative;
	margin-bottom: 40px;
}

.pricing-table ul{
	display: block;
}

.pricing-table ul li{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 20px;
	color: #666666;
	font-weight: 400;
	padding: 20px 0 19px;
	cursor: default;
	border-bottom: 2px solid #f5f5f5;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.pricing-table ul li:first-child{
	border-top: 1px solid #f5f5f5;
}

.pricing-table ul li:hover{
	background-color: #f5f5f5;
}

.pricing-table .price{
	position: relative;
	margin-bottom: 35px;
}

.pricing-table .price h2{
	position: relative;
	font-size: 30px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 10px;
}

.pricing-table .price span{
	position: relative;
	display: block;
	font-size: 20px;
	color: #fb4848;
	font-weight: 400;
}

.pricing-table .table-footer{
	position: relative;
}

.pricing-table .theme-btn{
	text-transform: capitalize;
	padding: 17px 50px;
}

/*** 

====================================================================
				Appointment Form Section
====================================================================

***/

.appointment-section{
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.appointment-section .auto-container{
	position: relative;
}

.appointment-section .form-column .inenr-column{
	position: relative;
	display: block;
	padding: 110px 0 100px;
}

.appointment-section .sec-title{
	margin-bottom: 50px;
}

.appointment-form{
	position: relative;	
}

.appointment-form .form-group{
	position:relative;
	margin-bottom:20px;
}

.appointment-form .form-group .icon{
	position: absolute;
	display: inline-block;
	right: 35px;
	top: 10px;
	height: 30px;
	line-height: 30px;
	font-size: 14px;
	color: #999999;
}

.appointment-form .ui-selectmenu-button.ui-button,
.appointment-form .form-group input[type="text"],
.appointment-form .form-group input[type="email"],
.appointment-form .form-group input[type="tel"],
.appointment-form .form-group input[type="url"],
.appointment-form .form-group textarea,
.appointment-form .form-group select{
	position: relative;
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: #999999;
    font-weight: 400;
    padding: 14px 20px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.appointment-form .form-group input:focus,
.appointment-form .form-group select:focus,
.appointment-form .form-group textarea:focus{
	border-color:#fb4848;
}

.appointment-form .form-group textarea{
	height: 130px;
	resize: none;
}

.appointment-form .form-group input[type="submit"],
.appointment-form button{
    position: relative;
    padding: 15px 50px;
    margin-top: 10px;
	text-transform: uppercase;
}

.appointment-form .form-group input[type="submit"]:hover,
.appointment-form button:hover{
	opacity: .85;
	color: #ffffff;
	background-color: #222222;
}

.appointment-form .ui-button .ui-icon{
	background:none;
	position:relative;
	top:0px;
	text-indent: 0;
	height: 22px;
}

.appointment-form .ui-button .ui-icon:before{
	font-family: 'Font Awesome 5 Free';
	content: "\f107";
	position:absolute;
	font-weight: 900;
	right:0px;
	top:0px;
	width:14px;
	font-size:14px;
	display:block;
	color:#999999;
	text-align:center;
	z-index:5;
}

.ui-menu .ui-menu-item{
	font-size:14px;
	font-weight: 400;
	color: #333333;
	border-bottom:1px solid #e0e0e0;
}

.ui-menu .ui-menu-item:last-child{
	border:none;	
}

.ui-menu .ui-menu-item-wrapper{
	position: relative;
    display: block;
    padding: 10px 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.appointment-section .image-column{
	position: absolute;
	right: 0;
	bottom: 0;
	height: 100%;
}

.appointment-section .image-column .inner-column{
	position: absolute;
	left: 15px;
	bottom: 0;
	width: 100%;
}

.appointment-section .image-column .inner-column .image{
	margin-right: -90px;
}

.appointment-section .image-column img{
	width: 100%;
	height: auto;
}

/*** 

====================================================================
			Appointment Page Style
====================================================================

***/

.appointment-page{
	position: relative;
	padding: 100px 0 160px;
}

.appointment-page .sec-title p{
	position: relative;
	font-size: 18px;
	line-height: 36px;
	color: #36404b;
	font-weight: 400;
	margin-top: 30px;
}

.appointment-page .title{
	position: relative;
	margin-bottom: 30px;
}

.appointment-page .title h3{
	position: relative;
	font-size: 20px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
}

.service-form{
	position: relative;
	display: block;
}

.services-needed .check-box label,
.service-form .ui-selectmenu-button.ui-button,
.service-form input,
.service-form select,
.service-form textarea{
	position: relative;
	display: block;
	height: 50px;
	width: 100%;
	padding: 9px 20px;
	font-size: 14px;
	line-height: 30px;
	font-weight: 400;
	color: #aaaaaa;
	background: #ffffff;
	border: 1px solid #dddddd;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.vehicle-detail .form-group{
	position: relative;
	margin-bottom: 70px;
}

.service-form .form-group input:focus,
.service-form .form-group textarea:focus{
	border-color: #fb4848;
}

.service-form .ui-button .ui-icon{
	background:none;
	position:absolute;
	right: 0;
	top: 0;
	height: 48px;
	width: 48px;
	line-height: 48px;
	text-align: center;
	text-indent: 0;
	background: #dddddd;
}

.service-form  .ui-button .ui-icon:before{
	font-family: 'Font Awesome 5 Free';
    font-weight: 900; 
	content: "\f107";
	position:absolute;
	color:#333333;
	width: 46px;
	left: 0;
	top: 0;
	height: 46px;
}

/*=================================
	Range slider
=================================*/

.vehicle-year-slider .range-slider-one{
    position: relative;
    min-height: 50px;
    overflow: hidden;
    max-width: 400px;
}

.vehicle-year-slider{
    margin-top: 20px;
	position: relative;
	float: left;
	min-width: 270px;
	background: #dddddd;
	border: 0;
}

.vehicle-detail .range-slider-one .input{
	float:right;
	max-width:75px;
    position: relative;
}

.vehicle-detail .range-slider-one .input input{
	background:none;
	color:#222222;
	font-size:16px;
	font-weight:400;
	width:75px;
	padding: 10px 15px;
	text-align: center;
}

.vehicle-detail .range-slider-one .ui-slider .ui-slider-range{
	top:0px;
	height: 10px;
	background-color :#333333;
}

.vehicle-detail .range-slider-one .ui-state-default,
.vehicle-detail .range-slider-one .ui-widget-content .ui-state-default{
	top:-10px;
	width:30px;
	height:30px;
	cursor:pointer;
	background-color :#ffffff;
	border: 2px solid #333333;
	border-radius: 50%;
}

.vehicle-detail .range-slider-one .ui-state-default:before{
	position: absolute;
	left: 5px;
	top: 5px;
	height: 16px;
	width: 16px;
	background: #fb4848;
	content: "";
	border-radius: 50%;
}

/*=================================
	Services Needed
=================================*/

.services-needed{
	position: relative;
	margin-bottom: 40px;
}

.services-needed .form-group{
	position: relative;
	margin-bottom: 30px;
}

.services-needed .check-box label{
	position: relative;
	display: block;
	height: 50px;
	width: 100%;
	line-height: 28px;
	padding: 10px 10px;
	padding-left: 80px;
	background: #ffffff;
	font-size: 14px;
	font-weight: 400;
	color: #aaaaaa;
	border: 1px solid #bbbbbb;
	cursor: pointer;
	margin-bottom: 0;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.check-box input[type="checkbox"]:checked + label{
	border-color: #fb4848;
}

.check-box label:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 48px;
	width: 48px;
	background: #ffffff;
	content: "";
	border-right: 1px solid #dddddd;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.check-box label:after {
	position: absolute;
	content: '\f00c'; /*checked*/
	left: 13px;
	top: 0;
	height: 48px;
	line-height: 48px;
	max-width: 0;
	font-size: 20px;
	color: #ffffff;
	opacity: 0.5;
	overflow: hidden;
	font-weight: 900;
	background: transparent;
	font-family: "Font Awesome 5 Free";
	text-align: center;
	-moz-transition: max-width 500ms ease;
	transition: max-width 500ms ease;
}

.check-box input[type="checkbox"] {
	display: none;
}

.check-box input[type="checkbox"]:checked + label:before{
	border-color: #fb4848;
	background: #fb4848;
}

.check-box input[type="checkbox"]:checked + label:after {
	max-width: 20px;
	opacity: 1;	
}

.appointment-page .contact-form {
	padding-bottom: 0;
}

.appointment-page .contact-form .form-group{
	margin-bottom: 30px;
}

.appointment-page .contact-form input{
    line-height: 26px;
    padding: 8px 20px;
    height: 50px;
    font-size: 14px;
    color: #aaaaaa;
}

.appointment-page .contact-form textarea{
	margin-bottom: 10px;
}

.appointment-page .contact-form button{
	width: auto;
	text-transform: uppercase;
	font-weight: 600;
}

/*** 

====================================================================
				Contact Section
====================================================================

***/

.contact-section{
	position: relative;
	padding-top: 60px;
}

.contact-form{
 	position: relative;
 	padding-bottom: 55px;
}

.contact-form .form-group{
	position:relative;
	margin-bottom:30px;	
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="email"],
.contact-form .form-group input[type="tel"],
.contact-form .form-group input[type="url"],
.contact-form .form-group textarea,
.contact-form .form-group select,
.cart-outer input[type="text"],
.cart-outer input[type="number"],
.cart-outer input[type="email"],
.cart-outer input[type="tel"],
.cart-outer input[type="url"],
.cart-outer textarea,
.cart-outer select {
	position: relative;
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: #bbbbbb;
    font-weight: 400;
    padding: 19px 20px;
    border: 1px solid #dddddd;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}
.cart-outer select {
	height: 60px;
}

.contact-form .form-group input:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus{
	border-color:#fb4848;
}

.contact-form .form-group textarea{
	height: 240px;
	resize: none;
}

.contact-form .form-group input[type="submit"],
.contact-form button{
    position: relative;
    font-size: 14px;
    line-height: 20px;
    padding: 20px 50px;
    display: block;
    width: 100%;
    color: #ffffff;
    text-transform: uppercase;
    background-color: #fb4848;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.contact-form .form-group input[type="submit"]:hover,
.contact-form button:hover{
    background-color: #6973bb;
    color: #FFF;
}

.contact-info{
	position: relative;
	padding-bottom: 50px;
}

.contact-info .image-column{
	position: relative;
	text-align: center;
}

.contact-info .image-column img{
	max-width: 100%;
	height: auto;
}

.contact-info .info-column{
	position: relative;
}

.contact-info .info-column h3{
	position: relative;
	font-size: 30px;
	line-height: 1.2em;
	color: #36404b;
	font-weight: 500;
	margin-bottom:50px;
}

.contact-info ul{
	position: relative;
	display: block;
}

.contact-info ul li{
	position: relative;
	display: block;
	margin-bottom: 30px;
	font-size: 14px;
	line-height: 30px;
	color: #666666;
	font-weight: 400;
	padding-left: 100px;
}

.contact-info ul li a{
	color: #666666;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.contact-info ul li a:hover{
	color: #fb4848;
}

.contact-info ul li strong{
	position: relative;
	font-size: 16px;
	line-height: 30px;
	color: #fb4848;
	font-weight: 500;
}

.contact-info ul li p > span{
	display: inline-block;
	font-size: 16px;
	color: #fb4848;
}

.contact-info ul li .icon{
	position: absolute;
	left: 0;
	top: 0;
	height: 60px;
	width: 60px;
	line-height: 60px;
	display: inline-block;
	font-size: 28px;
	color: #6a6a6a;
	text-align: center;
	border:1px solid #6a6a6a;
	border-radius: 50%;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.contact-info ul li:hover .icon{
	color: #fb4848;
	border-color: #fb4848;
}

/*** 

====================================================================
	Contact Map Section
====================================================================

 ***/

.map-section{
	position:relative;
}

.map-data{
	text-align:center;
	font-size:14px;
	font-weight:400;
	line-height:1.8em;
}

.map-data h6{
	font-size:16px;
	font-weight:700;
	text-align:center;
	margin-bottom:5px;
	color:#fb4848;
}

.map-canvas{
	height:400px;
}

/*** 

====================================================================
				Contact Info Section
====================================================================

***/

.contact-info-section{
	position: relative;
	padding: 65px 0 15px; 
	background-color: #d6e4e6 !important;
	margin-bottom: 5px;
}

.contact-info-block{
	position: relative;
	margin-bottom: 30px;
}

.contact-info-block .inner-box{
	position: relative;
	padding-top: 15px;
	padding-left: 120px;
	min-height: 90px;
}

.contact-info-block .icon{
	position: absolute;
	left: 0;
	top: 0;
	height: 90px;
	width: 90px;
	color: #232323;
	border:2px solid #232323;
	border-radius: 50%;
	font-size: 38px;
	line-height: 90px;
	text-align: center;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.contact-info-block p{
	position: relative;
	display: block;
	font-size: 15px;
	line-height: 30px;
	color: #232323;
	font-weight: 400;
}	

.contact-info-block p a{
	color: #232323;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.contact-info-block:hover .icon{
    background-color: #747ec3;
    border-color: #747ec3;
    color: #FFFFFF;
}

/*** 

====================================================================
				Clients Section
====================================================================

***/

.clients-section{
	position:relative;
}

.clients-section.alternate{
	background-color: #f2f2f2;
}

.clients-section .sponsors-outer{
	position:relative;
}

.clients-section .sponsors-outer .owl-dots,
.clients-section .sponsors-outer .owl-nav{
	position:relative;
	display:none;
}

.clients-section .sponsors-outer .image-box{
	position:relative;
	text-align:center;
	padding: 40px 0;
}

.clients-section .sponsors-outer .image-box img{
	max-width:100%;
	width: auto;
	height: auto;
	margin: 0 auto;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.clients-section .sponsors-outer .image-box:hover img{
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}

/***

==================================================================
				Call To Action
==================================================================

***/

.call-to-action{
	position: relative;
	display: block;
	padding: 20px 0;
    background-color: #525da0 !important;
    background-image: none !important;
}

.call-to-action .inner-container{
	position: relative;
	max-width: 1100px;
	margin: 0 auto;
	border: 1px solid #FFF;
	border-radius: 5px;
    padding: 25px;
}

.call-to-action .title-box{
	position: relative;
	float: left;
	max-width: 720px;
}

.call-to-action .title-box h3{
	position: relative;
	font-size: 20px;
	line-height: 2em;
	color: #ffffff !important;
	font-weight: 400;
	font-family: "Roboto", sans-serif;
}

.call-to-action .title-box h3 span{
	position: relative;
	display: inline-block;
	font-weight: 500;
	font-size: 24px;
	color: #ffffff !important;
	text-transform: uppercase;
	font-family: "Rubik", sans-serif;
}

.call-to-action .btn-box{
	position: relative;
	float: right;
	padding: 15px 0;
}

.call-to-action .btn-box a{
	position: relative;
	background-color: #22abdd !important;
}

.call-to-action .btn-box .theme-btn:hover{
	background-color: #222222;
	color: #ffffff;
}

.call-to-action.style-two{
	background-color: #222222;
	padding: 50px 0;
}

.call-to-action.style-two .title-box{
	padding: 10px 0;
	max-width: 900px;
}

.call-to-action.style-two .title-box p{
	position: relative;
	font-size: 20px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
}

.call-to-action.style-two .btn-box {
	padding: 0;
}

.call-to-action.style-two .btn-box .theme-btn{
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
}

.call-to-action.style-two .btn-box .theme-btn:hover{
	background-color: #ffffff;
	color: #fb4848;
}

/***

==================================================================
				Main Footer
==================================================================

***/

.main-footer{
	position:relative;
	background-repeat:no-repeat;
	background-position:center center;
	background-size: cover;			
	color:#ffffff;
}

.main-footer:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color:#515ba2;
	opacity: .9;
	content: "";
}

.main-footer .widgets-section{
	position:relative;
	padding: 55px 0px 30px;
}

.main-footer.alternate .widgets-section{
	padding-top: 180px;
}

.main-footer .footer-column{
	position:relative;
}

.main-footer .footer-widget{
	position:relative;
	margin-bottom:30px;		
}

.main-footer .footer-logo{
	position:relative;	
	margin-bottom: 25px;
}

.main-footer .footer-logo img{
	display:block;
	max-width:100%;
}

.main-footer .footer-column .widget-title{
	position: relative;
	font-size:18px;
	font-weight:500;
	color:#ffffff;	
	text-transform:capitalize;
	margin-bottom: 35px;
	padding-bottom: 15px;
}

.main-footer .footer-column .widget-title:after{
	position: absolute;
	left: 0;
	bottom: 0;
	height: 2px;
	width: 60px;
	background-color: #ffffff;
	content: "";
}

.main-footer .about-widget .text{
	font-size: 14px;
	line-height: 24px;
	color: #aaaaaa;
	font-weight: 400;
	margin-bottom: 30px;
}

.main-footer .about-widget h4{
	position: relative;
	display: inline-block;
	font-size: 14px;
	color: #ffffff;
	font-weight: 500;
}

.main-footer .about-widget .social-icon{
	position: relative;
	display: inline-block;
}

.main-footer .about-widget .social-icon li{
	position: relative;
	display: inline-block;
	margin-left: 15px;
}

.main-footer .about-widget .social-icon li a{
	position: relative;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-weight: 400;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}	

.main-footer .about-widget .social-icon li a:hover{
	color: #fb4848;
}

.main-footer .services-list{
	position: relative;
}

.main-footer .services-list li{
	position: relative;
	margin-bottom: 14px;
}

.main-footer .services-list li a{
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 20px;
	padding-left: 35px;
	color: #ffffff;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-footer .services-list li a:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
  	content: "\f101"; 
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-footer .services-list li a:hover:before,
.main-footer .services-list li a:hover{
	color: #fb4848;
}

/*=== Gallery Widget ===*/

.main-footer .gallery-widget .gallery-outer{
	position:relative;
	margin: 0px 4px;
}

.main-footer .gallery-widget .image{
	position:relative;
	float:left;
	width: 33.3333333%;
	padding-right: 4px;
	padding-left: 4px;
	margin-bottom: 10px;
	overflow: hidden;
}

.main-footer .gallery-widget .image img{
	display:block;
	width:100%;	
}

.main-footer .gallery-widget .image a{
	position: relative;
	display: block;
}

.main-footer .gallery-widget .image a:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	line-height: 85px;
	font-size: 16px;
	color: #ffffff;
	content: "";
	background-color: #fb4848;
	opacity: .80;
	text-align: center;
	z-index: 9;
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-footer .gallery-widget .image a:hover:before{
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

/*=== News Widget ===*/

.news-widget{
	position: relative;
}

.news-widget .post{
	position: relative;
	top: -8px;
	margin-bottom: 20px;
}

.news-widget .post h4{
	position: relative;
	font-size: 16px;
	line-height: 30px;
	font-weight: 400;
}

.news-widget .post h4 a{
	display: inline-block;
	color: #ffffff;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-widget .post h4 a:hover{
	color: #ffffff;
}

.news-widget .post .date{
	position: relative;
	font-size: 16px;
	line-height: 30px;
	color: #fb4848;
	font-weight: 400;
}

.news-widget .post .date i{
	display: inline-block;
	margin-right: 10px;
}

/*=== Footer Bottom ===*/

.footer-bottom{
	position: relative;
	display: block;
	background-color: rgba(255,255,255,.10);
}

.footer-bottom .copyright-text{
	position: relative;
	text-align: center;
}

.footer-bottom .copyright-text p{
	padding: 30px 0;
	font-size : 14px;
	line-height: 20px;
	color: #ffffff;
	font-weight: 400;
}

.footer-bottom .copyright-text a{
	color: #ffffff;
	display: inline-block;
}

.footer-bottom .copyright-text a:hover{
	text-decoration: underline;
}

/***

==================================================================
			Sidebar Page Container
==================================================================

***/

.sidebar-page-container{
	position: relative;
	padding: 60px 0 50px; 
}

.sidebar-page-container .sidebar-side,
.sidebar-page-container .content-side{
	position: relative;
	margin-bottom: 50px;
}

.sidebar-widget{
    position: relative;
    margin-bottom:55px;
}

.sidebar-side .sidebar-widget:last-child{
	margin-bottom: 0;
}

.sidebar-page-container .sidebar-title{
	position: relative;
	margin-bottom: 35px;
}

.sidebar-page-container .sidebar-title h2{
	position: relative;
	display: block;
	font-size: 20px;
	line-height: 1.2em;
	color: #36404b;
	text-transform: uppercase;
	font-weight: 500;
}

/*Search Box Widget*/

.sidebar .search-box{
	position: relative;
}

.sidebar .search-box .form-group{
	position:relative;
	margin:0px;	
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"]{
	position:relative;
	display:block;
	font-size:14px;
	color:#aaaaaa;
	line-height:28px;
	padding:10px 25px;
	padding-right: 60px;
	height:50px;
	width:100%;
	border:1px solid #eeeeee;
	background-color: #ffffff;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.sidebar .search-box .form-group input:focus{
	border-color:#fb4848;	
}

.sidebar .search-box .form-group button{
	position:absolute;
	right:0;
	top:0;
	width:50px;
	height:50px;
	line-height: 50px;
	text-align:center;
	display:block;
	font-size:16px;
	color:#fb4848;
	background:none;
	font-weight:normal;
}

/*=== Categories ===*/

.category-list{
	position: relative;
	display: block;
}

.category-list li{
	position: relative;
	border: 1px solid #eeeeee;
	margin-bottom: -1px;
}

.category-list li a{
	position: relative;
	display: block;
	font-size: 14px;
	color: #666666;
	font-weight: 400;
	line-height: 28px;
	padding: 10px 0;
	padding-left: 40px;
	-moz-transition: all 200ms ease;
	transition: all 200ms ease;
}

.category-list li a:before{
	position: absolute;
	left: 20px;
	height: 50px;
	top: 0;
	line-height: 48px;
	font-size: 14px;
	color: #fb4848;
	content: "\f105";
	font-weight: 900;
	font-family: "Font Awesome 5 Free";
	-moz-transition: all 200ms ease;
	transition: all 200ms ease;
}

.category-list li a span{
	float: right;
	font-size: 14px;
	margin-right: 20px;
	color: #333333;
	-moz-transition: all 200ms ease;
	transition: all 200ms ease;
}

.category-list li a:hover{
	color: #fb4848;
	background: #eeeeee;
}

/*=== Latest News ===*/

.latest-news .post{
	position: relative;
	padding-left: 100px;
	margin-bottom: 30px;
	min-height: 70px;
}

.latest-news .post-thumb{
	position: absolute;
	left: 0;
	top: 0;	
	height: 70px;
	width: 85px;
	margin-bottom: 20px;
}

.latest-news .post-thumb img{
	position: relative;
	display: block;
	width: 100%;
}

.latest-news .post h3{
	position: relative;
	font-size: 16px;
	line-height: 24px;
	color: #36404b;
	font-weight: 500;
	top: 0px;
	margin-bottom: 2px;
}

.latest-news .post h3 a{
	color: #36404b;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.latest-news .post:hover h3 a{
	color: #fb4848;
}

.latest-news .post .post-info{
	position: relative;
	font-size: 14px;
	line-height: 24px;
	color: #777777;
}

.latest-news .post .post-info span{
	position: relative;
	margin-right: 10px;
	font-size: 16px;
	color: #aaaaaa;
}

/*=== Tags ===*/

.tag-list{
	position: relative;
	display: block;
	margin-right: -10px;
}

.tag-list li{
	position: relative;
	float: left;
	margin-bottom: 10px;
	margin-right: 10px;
} 

.tag-list li a{
	position: relative;
    display: block;
    font-size: 13px;
    color: #666666;
    line-height: 15px;
    padding: 9px 18px;
    text-align: center;
    border: 1px solid #cccccc;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.tag-list li a:hover{
	background-color: #fb4848;
	border-color: #fb4848;
	color: #ffffff;
}

/*** 

====================================================================
	Comments Area
====================================================================

 ***/

.sidebar-page-container .group-title{
	position: relative;
	display: block;
	margin-bottom: 50px;
}

.sidebar-page-container .group-title h2{
	color: #36404b;
	font-size: 24px;
	line-height: 1.2em;
	font-weight: 500;
	text-transform: uppercase;
}

.sidebar-page-container .comments-area{
	position:relative;
	margin-bottom:20px;
	margin-top:60px;
}

.sidebar-page-container .comments-area .comment-box{
	position:relative;
	background-color: #f7f7f7;
	margin-bottom: 70px;
}

.sidebar-page-container .comments-area .comment-box.reply-comment{
	margin-left:80px;
}

.sidebar-page-container .comments-area .comment{
	position:relative;
	min-width: 120px;
	padding:30px 30px 30px 180px;
}

.sidebar-page-container .comments-area .comment .comment-inner{
	position:relative;
}

.sidebar-page-container .comments-area .comment .comment-reply{
	position:relative;
	font-size: 14px;
	line-height: 20px;
	color: #fb4848;
	font-weight: 400;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.sidebar-page-container .comments-area .comment-box .author-thumb{
	position:absolute;
	left:30px;
	top:30px;
	width:120px;
	overflow:hidden;
	margin-bottom:20px;
}

.sidebar-page-container .comments-area .comment-box .author-thumb img{
	display:block;
}

.sidebar-page-container .comments-area .comment-info{
	position: relative;
	display: block;
}

.sidebar-page-container .comments-area .comment-info h4{
	color:#36404b;
	line-height:28px;	
	font-size:14px;
	font-weight:500;
	font-family: "Rubik", sans-serif;
	display: inline-block;
}

.sidebar-page-container .comments-area .comment-info span{
	display: inline-block;
	font-weight:400;
	font-size:12px;
	line-height: 28px;
	color:#36404b;
	margin-left:15px;
}

.sidebar-page-container .comments-area .comment-box .text{
	color:#777777;
	font-size:14px;
    margin-top:5px;
    line-height: 28px;
    font-weight: 500;
	margin-bottom:5px;
}

/*** 

====================================================================
	Comment Form
====================================================================

 ***/

.comment-form{
	position:relative;
	margin-top: 100px;
}

.comment-form .form-group{
	position:relative;
	margin-bottom:30px;
}

.comment-form .form-group:last-child{
	margin-bottom: 0;
}

.comment-form input,
.comment-form select,
.comment-form textarea{
	position:relative;
	display:block;
	width:100%;
	font-size:14px;
	color: #aaaaaa;
	font-weight:400;
	line-height:20px;
	padding:14px 22px;
	background-color:#ffffff;
	border:1px solid #dddddd;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.comment-form .form-group textarea{
	height: 190px;
	resize: none;
	padding: 18px 22px;
	margin-bottom: 20px;
}

.comment-form .form-group input:focus,
.comment-form .form-group textarea:focus{
	border-color:#fb4848;
}

.comment-form button{
	position:relative;
	padding: 8px 30px;
	font-weight: 600;
	font-size: 14px;
	line-height: 30px;
}

/*** 

====================================================================
				Error Page
====================================================================

***/

.error-section{
	position:relative;
	text-align:center;
	padding:150px 0px 210px;
	background-position: left center;
	background-repeat: no-repeat;
}

.error-section .error-image{
	position: relative;
	text-align:center;
	margin-bottom: 30px;
}

.error-section .error-image .image{
	position:relative;
	max-width:100%;
	display:inline-block;
}

.error-section h2{
	position:relative;
	color:#bbbbbb;
	font-size:72px;
	font-weight:500;
	line-height:1.2em;
	text-transform: uppercase;
	margin-bottom: 45px;
}

.error-section h2 .dark{
	color: #36404b;
}

.error-section p{
	font-size:24px;
	color:#777777;
	font-weight:400;
	line-height: 1.2em;
	margin-bottom:90px;
}

.error-section .theme-btn{
	text-transform: uppercase;
	font-weight: 700;
	padding: 14px 45px;
	line-height: 20px;
	margin: 0 15px 20px;
}

/*** 

====================================================================
		Shop Page Section
====================================================================

***/

.shop-upper-box{
	position: relative;
	margin-bottom: 50px;
}

.shop-upper-box .items-label{
	position: relative;
	font-size: 16px;
	line-height: 30px;
	color: #aaaaaa;
	padding: 10px 0;
}

.sort-by .ui-selectmenu-button.ui-button{
	position:relative;
	display:block;
	height:50px;
	width:100%;
	min-width: 270px;
	line-height:28px;
	text-align: left;
	padding:10px 20px;
	font-size: 14px;
	border: 1px solid #eeeeee;
	color: #aaaaaa;
	font-weight: 400;
	text-transform:capitalize;
	background:#ffffff;
}

.sort-by .ui-button .ui-icon{
	background:none;
	position:relative;
	top:0px;
	text-indent:0px;
	color:#fb4848;	
}

.sort-by .ui-button .ui-icon:before{
	font-family: 'Font Awesome 5 Free';
	content: "\f107";
	font-weight: 900;
	position:absolute;
	right:0px;
	top:4px !important;
	top:13px;
	width:10px;
	height:20px;
	display:block;
	color:#aaaaaa;
	line-height:20px;
	font-size:16px;
	text-align:center;
	z-index:5;
}

.shop-item {
	position: relative;
	margin-bottom: 65px;
}

.shop-item .inner-box{
	position: relative;
}

.shop-item .image{
	position: relative;
}

.shop-item .image img{
	display: block;
	width: 100%;
}

.shop-item .overlay-box{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	text-align: center;	
	background: rgba(255,255,255,.80);
	-moz-transition: all 400ms ease;
	transition: all 400ms ease;
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
}

.shop-item:hover .overlay-box{
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.shop-item .overlay-box .icon-box{
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	margin-top: -25px;
	text-align: center;
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);

}

.shop-item:hover .overlay-box .icon-box{
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-moz-transition: all 400ms ease;
	transition: all 400ms ease;
	-moz-transition-delay: 300ms;
	transition-delay: 300ms;
}

.shop-item .overlay-box .cart-btn{
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	padding: 10px 25px;
	background-color: #222222;
	-moz-transition: all 400ms ease;
	transition: all 400ms ease;
}

.shop-item .overlay-box .cart-btn i{
	margin-right: 10px;
}

.shop-item .overlay-box .cart-btn:hover{
	background-color: #fb4848;
}

.shop-item .lower-content{
	position: relative;
	padding: 30px 0px 0;	
	text-align: center;
}

.shop-item .lower-content h3{
	position: relative;
	font-size: 20px;
	color: #36404b;
	line-height: 1.2em;
	font-weight: 500;
	text-transform: uppercase;
	top: -5px;
	margin-bottom: 10px;
}

.shop-item .lower-content h3 a{
	color: #36404b;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
} 

.shop-item .lower-content h3 a:hover{
	color: #fb4848;
}	

.shop-item .lower-content .price{
	position: relative;
	display: block;
	font-size: 16px;
	color: #36404b;
	line-height: 25px;
	margin-bottom: 15px;
}

.shop-item .lower-content .rating {
	position: relative;
	font-size: 13px;
	line-height: 20px;
	color: #fb4848;
	font-weight: 900;
}

.shop-item .lower-content .price{
	font-size: 16px;
	line-height: 20px;
	color: #36404b;
	font-weight: 500;
	margin-bottom: 20px;
}

.shop-item .lower-content .price del{
	color: #888888;
	text-decoration: line-through;
	margin-right: 10px;
}

/*Comment Form*/

.shop-comment-form{
	position:relative;
	margin-top:50px;
}

.shop-comment-form h2{
	position:relative;
	color:#36404b;
	font-size:30px;
	font-weight:500;
	margin-bottom:10px !important;
	text-transform:uppercase;
}

.shop-comment-form .mail-text{
	position:relative;
	color:#777777;
	font-size:16px;
	margin-bottom:15px;
}

.shop-comment-form .group-title{
	margin-bottom:20px;
}

.shop-comment-form .rating-box{
	position:relative;
	margin-bottom:20px;
}

.shop-comment-form .rating-box .text{
	position:relative;
	font-size:16px;
	color:#222222;
	margin-bottom:15px;
}

.shop-comment-form .rating-box .rating{
	position:relative;
	display:inline-block;
}

.shop-comment-form .rating-box .rating .fa{
	position:relative;
	display:inline-block;
}

.shop-comment-form .rating-box .rating a{
	position:relative;
	color:#333333;
	font-size:14px;
	display:inline-block;
}

.shop-comment-form .rating-box .rating a:hover{
	color:#fb4848;
}

.shop-comment-form .form-group{
	position:relative;
	margin-bottom:20px;
}

.shop-comment-form .form-group label{
	position:relative;
	color:#333333;
	font-size:16px;
	font-weight:400;
}

.shop-comment-form .form-group:last-child{
	margin-bottom:0px;
}

.shop-comment-form .form-group input[type="text"],
.shop-comment-form .form-group input[type="password"],
.shop-comment-form .form-group input[type="tel"],
.shop-comment-form .form-group input[type="email"],
.shop-comment-form .form-group select{
	position:relative;
	display:block;
	width:100%;
	line-height:28px;
	padding:10px 15px;
	border:1px solid #dddddd;
	height:50px;
	color:#848484;
	font-weight:300;
	background:#ffffff;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.shop-comment-form .form-group input[type="text"]:focus,
.shop-comment-form .form-group input[type="password"]:focus,
.shop-comment-form .form-group input[type="tel"]:focus,
.shop-comment-form .form-group input[type="email"]:focus,
.shop-comment-form .form-group select:focus,
.shop-comment-form .form-group textarea:focus{
	border-color:#fb4848;
}

.shop-comment-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:26px;
	padding:10px 15px;
	color:#848484;
	border:1px solid #dddddd;
	height:120px;
	font-weight:300;
	background:#ffffff;
	resize:none;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.shop-comment-form button{
	position:relative;
	font-weight:400;
	padding: 23px 45px;
	margin-top: 10px;
}

.shop-comment-form input:focus,
.shop-comment-form select:focus,
.shop-comment-form textarea:focus{
	border-color:#f06529;	
}

/*Sidebar Range slider */

.sidebar-widget .range-slider-one{
	position:relative;
	min-height:48px;
}

.sidebar-widget .range-slider-one .title{
	position:relative;
	float:left;
	color:#333333;
	font-size:15px;
	font-weight:400;
	margin-top:8px;
}

.sidebar-widget .range-slider-one .title:before{
	position:absolute;
	content:'$';
	right:-14px;
	top:0px;
	color:#333333;
	font-size:15px;
	font-weight:400;
}

.sidebar-widget .range-slider-one .input{
	float:right;
    left: 8px;
	max-width:75px;
	padding-left:8px;
	margin-top:8px;
    position: relative;
}

.sidebar-widget .range-slider-one .input input{
	background:none;
	color:#777777;
	font-size:18px;
	font-weight:400;
	width:auto;
	text-align:left;
}

.sidebar-widget .range-slider-one .ui-widget.ui-widget-content{
	height:3px;
	border:none;
	margin-bottom:45px;
	background:#cccccc;	
}

.sidebar-widget .range-slider-one .ui-slider .ui-slider-range{
	top:0px;
	height:4px;
	background:#222222;	
}

.sidebar-widget .range-slider-one .ui-state-default,
.sidebar-widget .range-slider-one .ui-widget-content .ui-state-default{
	top:-5px;
	width:15px;
	height:15px;
	z-index: 99;
	background:#fb4848;
	cursor:pointer;	
	border:0;
}

.sidebar-widget .range-slider-one .theme-btn{
	padding: 12px 30px;
	font-weight: 500;
	text-transform: capitalize;
}

/*Sidebar Realated Posts */

.sidebar .related-posts .post{
	position:relative;
	padding-left:90px;
	min-height:70px;
	margin-bottom:25px;
}

.sidebar .related-posts .post:last-child{
	margin-bottom:0px;
}

.sidebar .related-posts .post .post-thumb{
	 position:absolute;
	 left:0px;
	 top:0px;
	 width:70px;
}

.sidebar .related-posts .post .post-thumb img{
	display:block;
	width:100%;	
}

.sidebar .related-posts .post h4{
	top:-2px;
	font-size:18px;
	line-height: 1.2em;
	font-weight:500;
	color:#36404b;
	text-transform:capitalize;
}

.sidebar .related-posts .post h4 a{
	color:#36404b;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
}

.sidebar .related-posts .post a:hover{
	color:#fb4848;	
}

.sidebar .related-posts .rating{
	position: relative;
}

.sidebar .related-posts .rating span{
	position:relative;
	display:inline-block;
	font-size:12px;
	line-height:15px;
	color:#fb4848;
}

.sidebar .related-posts .price{
	position:relative;
	font-size:16px;
	color:#777777;
	font-weight:400;
}

/*** 

====================================================================
			Shop Details
====================================================================

***/

.shop-single{
	position:relative;
	padding: 120px 0 150px; 
}

.product-details .basic-details{
	position:relative;
	margin-bottom: 65px;
}

.product-details .image-column,
.product-details .info-column{
	margin-bottom:20px;
}

.product-details .info-column{
	position:relative;
}

.product-details .info-column .inner-column{
	position: relative;
}

.product-details .image-column .image-box img{
	position:relative;
	display:block;
	width:100%;	
}

.product-details .basic-details .details-header{
	position:relative;
	margin-bottom:35px;	
	margin-top: 15px;
}

.product-details .basic-details .details-header h4{
	font-size:24px;
	font-weight:500;
	color:#36404b;
	line-height:1.2em;
	margin-bottom:15px;
}

.product-details .basic-details .details-header h4 a{
	color:#36404b;	
}

.product-details .basic-details .item-price{
	position: relative;
	font-size:18px;
	font-weight:400;
	color:#36404b;
	line-height:24px;
	letter-spacing:	1px;
	display: block;
	margin-bottom: 10px;
}

.product-details .basic-details .item-price del{
	color: #888888;
}

.product-details .basic-details .rating{
	display: inline-block;
	font-size:16px;
	color:#fb4848;
	margin-right: 15px;
}

.product-details .basic-details .rating .fa{
	display:inline-block;
}

.product-details .basic-details .text{
	font-size:14px;
	line-height:30px;
	color:#777777;
	margin-bottom: 35px;
}

.product-details .basic-details .theme-btn{
	padding: 17px 45px;
	font-weight: 500;
}

.product-details .basic-details .item-quantity{
	position: relative;
	float: left;
	margin-right: 22px;
	max-width: 150px;
	border: 1px solid #777777;
	border-radius: 3px;
	margin-bottom: 15px;
}

.product-details .basic-details .quantity-spinner,
.cart-section input.quantity-spinner{
	line-height:28px;
	width: 100px;
	padding:10px 15px !important;
	height:50px !important;
	box-shadow:none !important;	
	border-right:1px solid #777777;
	text-align: center;
	font-size: 20px;
	color: #666666;
}

.product-details .basic-details .bootstrap-touchspin .input-group-btn-vertical > .btn,
.cart-section .bootstrap-touchspin .input-group-btn-vertical > .btn{
	background:none;	
	border: 0;
	margin-right: 10px;
	color: #777777;
	text-align: center;

}

.bootstrap-touchspin .input-group-btn-vertical{
	width: 50px;
	text-align: center;
	border:1px solid #777777;
	border-left: 0;
}

.bootstrap-touchspin .input-group-btn-vertical i{
    left: 0;
    width: 100%;
}

/*** 

====================================================================
	Product Tabs Style
====================================================================

***/

.product-info-tabs{
	position:relative;
	margin-bottom:50px;
}

.product-info-tabs .prod-tabs{
	position:relative;
}

.product-info-tabs .prod-tabs .tab-btns{
	position:relative;
	z-index:1;
}

.product-info-tabs .prod-tabs .tab-btns .tab-btn{
	position:relative;
	float:left;
	font-size: 16px;
	line-height: 20px;
	color: #000000;
	font-weight: 500;
	padding: 20px 30px;
	text-transform: uppercase;
	border:1px solid #dddddd;
	margin-bottom: -1px;
}

.product-info-tabs .prod-tabs .tab-btns .tab-btn.active-btn{
	background:#eeeeee;
}

.product-info-tabs .prod-tabs .tabs-container{
	position:relative;
}

.product-info-tabs .prod-tabs .tabs-container .tab{
	position:relative;
	padding:30px 30px;
	display:none;
	border:1px solid #dddddd;
}

.product-info-tabs .prod-tabs .tabs-container .tab.active-tab{
	display:block;	
}

.product-info-tabs .prod-tabs .tabs-container .tab p,
.product-info-tabs .prod-tabs .tabs-container .tab h2,
.product-info-tabs .prod-tabs .tabs-container .tab h3,
.product-info-tabs .prod-tabs .tabs-container .tab h4,
.product-info-tabs .prod-tabs .tabs-container .tab h5,
.product-info-tabs .prod-tabs .tabs-container .tab h6{
	margin-bottom:15px;	
}

.product-info-tabs .prod-tabs .tabs-container .tab h3{
	font-size:30px;
	font-weight:500;
	text-transform:uppercase;
	color:#36404b;
	margin-bottom:15px;
}

.product-info-tabs .prod-tabs .tabs-container .tab h4{
	font-size:14px;
	font-weight:600;
	text-transform:uppercase;
	color:#222222;
	margin-bottom:15px;
}

.product-info-tabs .prod-tabs #prod-description{
	position: relative;
	padding: 30px 30px;
	margin-bottom: 110px;
}

.product-info-tabs .prod-tabs #prod-description p{
	position: relative;
	font-size: 16px;
	line-height: 28px;
	color: #777777;
}

.product-info-tabs .prod-tabs .reviews-container{
	position:relative;	
	padding-left: 30px;
}

.product-info-tabs .prod-tabs .reviews-container .review-box{
	position:relative;
	margin-bottom:30px;
	min-height:110px;
}

.product-info-tabs .prod-tabs .reviews-container .review-box.reply{
	margin-left: 100px;
}

.product-info-tabs .prod-tabs .reviews-container .rev-thumb{
	position:absolute;
	left:-35px;
	top:20px;
	width:70px;
	height:70px;
	margin-bottom:20px;
	background:#ffffff;
	z-index: 99;
	border-radius:3px;
}

.product-info-tabs .prod-tabs .reviews-container .rev-thumb img{
	display:block;
	width:70px;
	height:70px;
}

.product-info-tabs .prod-tabs .reviews-container .rev-content{
	position:relative;
	padding:15px;
	padding-left: 65px;
	border:1px solid #e0e0e0;
	background:#ffffff;	
}

.product-info-tabs .prod-tabs .reviews-container .rating{
	color:#fb4848;
	font-size:14px;
	margin-bottom: 2px;
}

.product-info-tabs .prod-tabs .reviews-container .rating .fa{
	display: inline-block;
}

.product-info-tabs .prod-tabs .reviews-container .rev-info{
	font-size:16px;
	letter-spacing:1px;
	line-height: 24px;
	color: #888888;
	font-weight: 400;
}

.product-info-tabs .prod-tabs .reviews-container .rev-info span{
	display: inline-block;
	color: #36404b;
	margin-right: 25px;
	font-weight: 500;
	font-family: "Rubik", sans-serif;
}

.product-info-tabs .prod-tabs .reviews-container .rev-text{
	font-size:14px;
	color: #888888;
	line-height: 24px;
}

.product-info-tabs .prod-tabs .reviews-container .rev-text p{
	margin: 0;
}

.product-info-tabs .prod-tabs .add-review{
	position:relative;
	margin:30px 0px 0px;
	font-size:14px;
}

.product-info-tabs .prod-tabs .add-review .rating{
	position:relative;
	padding-top:8px;	
}

.product-info-tabs .prod-tabs .add-review .rating .rate-box{
	position:relative;
	display:inline-block;
	margin-right:10px;
	font-size:14px;
	color:#555555;	
}

.product-info-tabs .prod-tabs .add-review .rating .rate-box:hover,
.product-info-tabs .prod-tabs .add-review .rating .rate-box:focus,
.product-info-tabs .prod-tabs .add-review .rating .rate-box:active{
	color:#fb4848;	
}

.product-info-tabs .prod-tabs .add-review label{
	position:relative;
	display:block;
	font-size:14px;
	margin-bottom:5px;
	font-weight:400;
	color:#303030;	
}

.product-info-tabs .prod-tabs .add-review h3{
	margin-bottom:20px;	
}

.product-info-tabs .prod-tabs .add-review .form-group{
	position:relative;
	margin-bottom:20px;	
}

.product-info-tabs .prod-tabs .add-review .form-group input[type="text"],
.product-info-tabs .prod-tabs .add-review .form-group input[type="password"],
.product-info-tabs .prod-tabs .add-review .form-group input[type="tel"],
.product-info-tabs .prod-tabs .add-review .form-group input[type="email"],
.product-info-tabs .prod-tabs .add-review .form-group select{
	position:relative;
	display:block;
	width:100%;
	line-height:22px;
	padding:9px 15px;
	color:#222222;
	border:1px solid #e0e0e0;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
}

.product-info-tabs .prod-tabs .add-review .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:22px;
	padding:8px 15px;
	color:#222222;
	border:1px solid #e0e0e0;
	height:150px;
	resize:none;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
}

.product-info-tabs .prod-tabs .add-review .form-group textarea:focus,
.product-info-tabs .prod-tabs .add-review .form-group input:focus,
.product-info-tabs .prod-tabs .add-review .form-group select:focus{
	border-color: #fb4848;
}

.product-info-tabs .prod-tabs .add-review .form-group button{
	font-weight: 500;
	padding: 22px 35px;
}

/*====================================================================
				CheckOut Section
====================================================================*/

.checkout-page{
	position:relative;
	padding:120px 0px 150px;
}

.checkout-page .default-links{
	position:relative;
	margin-bottom:50px;	
}

.checkout-page .default-links li{
	line-height:25px;
	padding:25px 25px;
	font-size:16px;
	color:#ffffff;
	background-color :#222222;
	margin-bottom: 40px;
}

.checkout-page .default-links li .fa{
	position:relative;
	font-size:24px;
	line-height: 25px;
	color:#fb4848;
	margin-right:20px;
}

.checkout-page .default-links li a{
	color:#fb4848;
	font-size: 16px;
	display: inline-block;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.checkout-page .default-links li a:hover{
	color: #ffffff;
}	

.checkout-page .sec-title{
	margin-bottom: 20px;
} 

.checkout-form{
	position:relative;
	margin-bottom: 50px;
}

.checkout-form .column{
	padding-right: 30px;
}

.checkout-form .form-group{
	position:relative;
	margin-bottom: 15px;
}

.checkout-form .form-group .field-label{
	display:block;
	line-height:24px;
	text-transform:capitalize;
	color:#222222;	
	font-size:15px;
	font-weight:400;
	margin-bottom:10px;
}

.checkout-form .form-group .field-label span{
	position: relative;
	font-size: 16px;
	color: #fb4848;
	display: block;
	font-weight: 500;
}

.checkout-form .form-group .field-label sup{
	top:-1px;
	font-size:15px;
}

.checkout-form .form-group .text{
	position:relative;
	color:#bbbbbb;
	font-size:15px;
	line-height: 24px;
}

.checkout-form input[type="text"],
.checkout-form input[type="email"],
.checkout-form input[type="password"],
.checkout-form input[type="tel"],
.checkout-form input[type="number"],
.checkout-form input[type="url"],
.checkout-form select,
.checkout-form textarea{
	position:relative;
	display:block;
	width:100%;
	background:#ffffff;
	font-size: 16px;
	line-height:26px;
	color: #777777;
	padding:12px 22px;
	height:50px;
	border:1px solid #e0e0e0;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
}

.checkout-form select{
	cursor:pointer;
	-webkit-appearance:none;
	-ms-appearance:none;
	-o-appearance:none;
	-moz-appearance:none;
	background:#ffffff url(../images/icons/icon-select.png) right center no-repeat;
}

.checkout-form textarea{
	resize:none;
	height:60px !important;	
	padding-left: 30px;
}

.checkout-form input:focus,
.checkout-form select:focus,
.checkout-form textarea:focus{
	border-color: #b2dd4c;
}

.checkout-page .check-box{
	line-height:24px;
	font-size:14px;
	font-weight:normal;
	padding-top:5px;	
}

.checkout-page .check-box label{
	position:relative;
	top:-1px;
	font-weight:normal;
	padding:0px;
	font-size:16px;
	cursor:pointer;
	color:#333333;
}

.checkout-page .lower-content{
	margin-top:30px;
}

.checkout-page .lower-content .order-column{
	margin-bottom:40px;
}

.checkout-page .lower-content .column h2{
	position:relative;
	color:#222222;
	font-size:24px;
	margin-bottom:30px;
	padding-bottom:12px;
}

.checkout-page .lower-content .column h2:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:40px;
	height:2px;
	background-color:#fa9928;
}

/*Coupon Box*/

.coupon-box{
	position:relative;
	padding:25px 25px;
	margin-bottom:25px;
	background-color:#f5f5f5;
}

.coupon-form{
	position:relative;
}

.coupon-form .form-group{
	position:relative;
	margin-bottom:0px;
}

.coupon-form .form-group input[type="text"]{
	position:relative;
	display:block;
	width:100%;
	line-height:28px;
	padding:10px 20px;
	height:44px;
	font-size:14px;
	border:1px solid #cccccc;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.coupon-form .form-group input[type="text"]:focus{
	border-color: #b2dd4c;
}

.coupon-form button{
	margin-top:0px;
	font-size:16px;
	font-weight:700;
	border-radius:0px;
	-webkit-border-radius:0px;
	-moz-border-radius:0px;
	-ms-border-radius:0px;
	-o-border-radius:0px;
	padding:8px 26px;
	text-transform:capitalize;
}

.order-box{
	position:relative;
	margin-bottom: 50px;
}

.order-box .sec-title{
	margin-bottom: 25px;
}

.order-box .sec-title h2{
	font-size: 28px;
}

.order-box .title-box{
	position:relative;
	padding:15px 30px;
	font-weight: 500;
	color: #ffffff;
	background-color :#fb4848;
}

.order-box .title-box .col{
	position:relative;
	width:50%;
	float:left;
	color:#ffffff;
	font-size: 16px;
	line-height: 25px;
}

.order-box ul{
	position:relative;
}

.order-box ul li{
	position:relative;
	padding: 25px 30px;
	font-size: 16px;
	line-height: 30px;
	border-bottom:1px solid #cccccc;
}

.order-box ul li strong{
	color:#222222;
	font-weight: 500;
	font-size:16px;
}

.order-box ul li span{
	width:50%;
	float:right;
	color:#fb4848;
	font-size:16px;
}

.order-box ul li span.free{
	color:#777777;
}

.order-box ul li:last-child{
	border-bottom: 0;
}

.payment-box{
	position:relative;
	margin-bottom: 50px;
}

.payment-box .upper-box{
	position:relative;
	padding:15px 35px;
	background-color:#f5f5f5;
	border-bottom:1px solid #b7b7b7;
}

.payment-options{
	position:relative;
	margin-top:20px;
	margin-bottom:20px;	
}

.payment-box .payment-options li{
	position:relative;
	margin-bottom:15px;	
}

.payment-box .payment-options li .radio-option{
	position:relative;		
}

.payment-box .payment-options li .radio-option label{
	position:relative;
	display:block;
	padding-left:30px;
	font-weight: 400;
	text-transform:capitalize;
	letter-spacing:1px;
	color:#222222;
	cursor:pointer;
	font-size:18px;
}

.payment-box .payment-options li .radio-option label strong{
	font-weight: 400;
}

.payment-box .payment-options li .radio-option input[type="radio"]{
	position:absolute;
	left:0px;
	top:3px;	
}

.payment-box .payment-options li .radio-option label .small-text{
	position:relative;
	display:none;
	letter-spacing:0px;
	text-transform:none;
	font-weight:normal;
	font-size:14px;
	color:#ffffff;
	line-height:1.8em;
	padding:15px 20px;
	margin-top:20px;
	background-color :#222222;
}

.payment-box .payment-options li .radio-option input:checked + label .small-text{
	display:block;
}

.payment-box .payment-options li .radio-option label .small-text:before{
	position:absolute;
	content:'';
	left:30px;
	top:-8px;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 8px solid #222222;
}

.payment-box .payment-options li .radio-option label img{
	position:relative;
	display:block;
	max-width:100%;
	padding-left:30px;	
	margin-top:20px;
}

.payment-box .payment-options li .radio-option .what-paypall{
	position:relative;
	display:block;
	color:#777777;
	font-size:16px;
	font-weight:700;
	margin-left:120px;
	margin-top:10px;
}

.payment-box .lower-box{
	position:relative;
	background-color: #f5f5f5;
	padding: 30px 35px;
}

.payment-box .lower-box .theme-btn{
	padding: 13px 25px;
	font-weight: 400;
}

/*** 

====================================================================
				Shopping Cart Section
====================================================================

***/

.cart-section{
	position:relative;
	padding:120px 0px 150px;
}

.cart-outer{
	position:relative;	
}

.checkout-page .cart-outer{
	margin-bottom:40px;	
}

.cart-outer .table-outer{
	position:relative;
	width:100%;
	overflow-x:	auto;
	margin-bottom: 40px;
}

.cart-outer .cart-table{
	width:100%;
	min-width:900px;	
}

.cart-table .cart-header{
	position:relative;
	width:100%;
	text-transform:uppercase;
	font-size:16px;
	background:#5b65ac;
	color:#ffffff;
	border:1px solid #cccccc;
}

.cart-table thead tr th{
	line-height:24px;
	padding:15px 25px;
	font-weight:500;
	font-size:16px;
	color: #ffffff;
	letter-spacing:1px;
	text-align:center;
	text-transform:uppercase;
	border-right:1px solid #cccccc;
}

.cart-table thead tr th .fa{
	font-size:18px;	
}

.cart-table tbody tr td{
	line-height:24px;
	padding:15px 15px 15px;
}

.cart-table tbody tr .qty{
	width:150px;
	padding: 0 50px;
}

.cart-table tbody tr .qty .quantity-spinner{
	background:#ffffff;	
}

.cart-table tbody tr .prod-column .column-box{
	position:relative;
	min-height:110px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb{
	position:relative;
	width:180px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb img{
	 display:block;
	 max-width:100%;
}

.cart-table tbody tr .prod-column .column-box h4{
	font-size:14px;
	color:#444444;
	font-weight:400;
	line-height:90px;
}

.cart-table tbody tr .prod-column .author{
	font-size:13px;
	color:#333333;
	margin-bottom:0px;
}

.cart-table tbody tr .sub-total{
	font-weight:400;
	color:#797979;
	font-size:17px;
}

.cart-table tbody tr .remove-btn{
	position:relative;
	font-size:14px;
	color:#FF0000;
	line-height:30px;
	font-weight:500;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.cart-table tbody tr .remove-btn .fa{
	position:relative;
	font-size:18px;
	line-height:30px;
}

.cart-table tbody tr .remove-btn:hover{
	color:#fb4848;
}

.cart-table tbody tr,
.cart-table tfoot tr{
	border-bottom:1px solid #cccccc;	
}

.cart-table tbody tr td,
.cart-table tfoot tr td{
	vertical-align:middle;
	color:#797979;
	font-size:17px;
	text-align:center;
	border-left:1px solid #cccccc;
}

.cart-table tbody tr td.prod-column{
	padding-left:48px;
	width: 290px;
}

.cart-table tbody tr td.total{
	font-size:18px;
	color:#333333;
}

.cart-table tbody tr td:last-child,
.cart-table tfoot tr td:last-child{
	border-right:1px solid #ebebeb;
	text-align:center;
}

.cart-table tbody tr td .prod-title{
	position:relative;
	font-size:16px;
	color:#333333;
	font-weight:400;
}

.cart-table tbody .available-info{
	position:relative;
	padding-left:50px;	
}

.cart-table tbody .available-info .icon{
	position:absolute;
	left:0px;
	top:5px;
	width:40px;
	height:40px;
	line-height:40px;
	text-align:center;
	font-size:18px;
	color:#ffffff;
	background:#223555;
	border-radius:50%;
}

.cart-section .cart-options{
	position:relative;
	margin-bottom:30px;
}

.cart-section .apply-coupon{
	position:relative;
}

.cart-section .apply-coupon .form-group{
	position:relative;
	float:left;
	margin-right:30px;	
}

.cart-section .apply-coupon .form-group input[type="text"]{
	display:block;
	line-height:20px;
	padding:14px 20px;
	border:1px solid #cccccc;
	width:225px;
	background:#ffffff;
	font-size: 14px;
	color: #777777;
}

.cart-section .cart-options .cart-btn,
.cart-section .coupon-btn{
	font-weight: 500;
	text-transform: capitalize;
	padding: 13px 45px;
}

.cart-section .totals-table{
	position:relative;
	margin-bottom:20px;
	border:1px solid #cccccc;
}

.cart-section .totals-table li{
	line-height:24px;
	padding:18px 30px;
	border-bottom:1px solid #cccccc;	
}

.cart-section .totals-table li:first-child{
	background-color: #dddddd;
}

.cart-section .totals-table li h3{
	position:relative;
	font-weight:500;
	color:#36404b;
	font-size:18px;
}

.cart-section .totals-table li:last-child{
	border-bottom:none;
	padding:40px 20px 50px;
}

.cart-section .totals-table .col{
	position:relative;
	display:block;
	float:left;
	line-height:24px;
	width:50%;
	color:#36404b;
	font-size:16px;
	text-transform:capitalize;
}

.cart-section .totals-table .proceed-btn{
	text-transform: capitalize;
	font-weight: 500;
	padding: 13px 50px;
}

.shipping-block{
	position:relative;
	margin-bottom:40px;
}

.shipping-block .inner-box{
	position:relative;
	display:block;
	padding:25px 27px 10px;
	border:1px solid #cccccc;
}

.shipping-block .inner-box h3{
	position:relative;
	font-size:24px;
	color:#36404b;
	font-weight:500;
	margin-bottom:5px;
	text-transform:capitalize;
}

.shipping-block .inner-box h4{
	position:relative;
	color:#fb4848;
	font-size:14px;
	font-weight: 400;
	text-transform:capitalize;
	margin-bottom:20px;
}

.shipping-form{
	position:relative;
}

.shipping-form .row{
	margin: 0 -10px;
}

.shipping-form .form-group{
	position:relative;
	padding: 0 10px;
	margin-bottom:20px;
}

.shipping-form .form-group input[type="text"],
.shipping-form .form-group input[type="password"],
.shipping-form .form-group input[type="tel"],
.shipping-form .form-group input[type="email"]{
	position:relative;
	display:block;
	width:100%;
	height:45px;
	line-height:23px;
	padding:10px 20px;
	font-size:14px;
	color: #777777;
	border:1px solid #cccccc;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.shipping-form .form-group input[type="text"]:focus,
.shipping-form .form-group input[type="password"]:focus,
.shipping-form .form-group input[type="tel"]:focus,
.shipping-form .form-group input[type="email"]:focus,
.shipping-form .form-group select:focus,
.shipping-form .form-group textarea:focus{
	border-color:#fb4848;
}

.shipping-form button{
	margin-top:0px;
	font-size: 16px;
	color: #ffffff;
	font-weight: 500;
	text-transform:capitalize;
	margin-top: 5px;
}

/*** 

====================================================================
	Login Section
====================================================================

***/

.login-section{
	position:relative;
	padding:120px 0px 150px;
}

.login-section h2{
	position:relative;
	font-size:30px;
	color:#36404b;
	font-weight:500;
	line-height:1.2em;
	margin-bottom:30px;
	text-transform:uppercase;
}

.login-form{
	position:relative;
	background-color: #f5f5f5;
	padding: 35px 45px 30px;
	margin-bottom: 50px;
}

.login-form .form-group{
	position:relative;
	margin-bottom:15px;
}

.login-form .form-group:nth-child(2){
	margin-bottom:20px;
}

.login-form .form-group label{
	position:relative;
	color:#666666;
	font-weight:400;
	font-size:14px;
	line-height: 24px;
	cursor:pointer;
	margin-bottom: 10px;
}

.login-form .form-group input[type="text"],
.login-form .form-group input[type="password"],
.login-form .form-group input[type="tel"],
.login-form .form-group input[type="email"]{
	position:relative;
	display:block;
	width:100%;
	line-height:28px;
	padding:10px 20px;
	height:50px;
	font-size:14px;
	border:1px solid #cccccc;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.login-form .form-group input[type="text"]:focus,
.login-form .form-group input[type="password"]:focus,
.login-form .form-group input[type="tel"]:focus,
.login-form .form-group input[type="email"]:focus,
.login-form .form-group select:focus,
.login-form .form-group textarea:focus{
	border-color:#fb4848;
}

.login-form button{
	margin-top:35px;
	font-size: 16px;
	padding: 15px 45px;
}

.login-form .form-group.remember label{
	display: block;
	margin: 0;
	font-size: 16px;
	color: #666666;
	text-transform: capitalize;
}

.login-form .psw{
	position:absolute;
	color:#666666;
	font-size:16px;
	min-width: 150px;
	margin-top: 20px;
}

.login-form .psw:hover{
	text-decoration:underline;	
}

/*** 

====================================================================
			coming Soon
====================================================================

***/

.coming-soon{
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 200px 0;
	text-align: center;
}

.coming-soon:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #222222;
	opacity: .30;
	content: '';
}

.coming-soon .inner-container{
	position: relative;
	padding: 0 15px;
	max-width: 1230px;
	margin: 0 auto;
}

.coming-soon .title{
	position: relative;
	display: block;
	margin-bottom: 120px;
}

.coming-soon .title h2{
	position: relative;
	font-size: 80px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 20px;
}

.coming-soon .title p{
	position: relative;
	display: block;
	font-size: 40px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 400;
}

.timer{
	position: relative;
}

.cs-countdown{
	position: relative;
	margin-bottom: 140px;
}

.cs-countdown div{
	position: relative;
	display: inline-block;
	height: 225px;
	width: 225px;
	text-align: center;
	margin: 0 35px;
}

.cs-countdown div:before{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	content: "";
	border:3px solid #ffffff;
	border-radius: 20px;
}

.cs-countdown span{
	position: relative;
	display: block;
	font-size: 100px;
	color: #ffffff;
	line-height: 225px;
	text-align: center;
}

.cs-countdown h6{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 20px;
	margin: 0 auto;
	font-size: 30px;
	color: #ffffff;
	font-weight: 400;
	text-align: center;
	text-transform: capitalize;
}

.coming-soon .subscribe-form{
	position: relative;
	display: block;
	text-align: center;
	margin-bottom: 120px;
}

.coming-soon .subscribe-form p{
	position: relative;
	font-size: 30px;
	line-height: 1.2em;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 60px;
}

.coming-soon .subscribe-form .form-group{
	position: relative;
	max-width: 810px;
	margin: 0 auto;
}

.coming-soon .subscribe-form input,
.coming-soon .subscribe-form select,
.coming-soon .subscribe-form textarea{
	position: relative;
	display: block;
	font-size: 24px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
	padding: 26px 40px;
	padding-right: 125px;
	border:3px solid #ffffff;
	width: 100%;
	z-index: 1;
	background-color: transparent;

}

.coming-soon .subscribe-form button{
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 120px;
	text-align: center;
	border-left: 3px solid #ffffff;
	font-size: 36px;
	color: #ffffff;
	line-height: 90px;
	background-color: transparent;
	z-index: 9;
}

.coming-soon .copyright{
	position: relative;
}

.coming-soon .copyright p{
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
}

.coming-soon .copyright p a{
	display: inline-block;
	color: #ffffff;
}

.coming-soon .copyright p a:hover{
	text-decoration: underline;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error{
	border-color:#ff0000 !important;	
}

.contact-form label.error{
	display:block;
	line-height:24px;
	padding:5px 0px 0px;
	margin:0px;
	text-transform:uppercase;
	font-size:11px;
	color:#ff0000;
	font-weight:500;	
}

.header-style-three .search-box-btn, .header-style-four .search-box-btn {
  border: 0px !important;
}

.galeria-empresa li {
    float: left;
    padding: 5px;
    width: 20%;
}

/* Theme Color */
.order-box ul li span,
.feature-tabs .tabs-content ul li:before,
.testimonial-block .designation,
.news-block .content-box .info li a:hover,
.news-block .content-box .read-more,
.mixitup-gallery .filters li:hover,
.shipping-block .inner-box h4,
.cart-table tbody tr .remove-btn:hover,
.checkout-form .form-group .field-label span,
.checkout-page .default-links li a,
.checkout-page .default-links li .fa,
.list-style-one li:before,
.about-us .content-column .list-style-one li:before,
.product-info-tabs .prod-tabs .add-review .rating .rate-box:hover,
.product-info-tabs .prod-tabs .add-review .rating .rate-box:focus,
.product-info-tabs .prod-tabs .add-review .rating .rate-box:active,
.product-info-tabs .prod-tabs .reviews-container .rating,
.product-details .basic-details .rating,
.sidebar .related-posts .rating span,
.sidebar .related-posts .post a:hover,
.shop-comment-form .rating-box .rating a:hover,
.shop-item .lower-content .rating,
.shop-item .lower-content h3 a:hover,
.sort-by .ui-button .ui-icon,
.sidebar-page-container .comments-area .comment .comment-reply,
.latest-news .post:hover h3 a,
.category-list li a:hover,
.category-list li a:before,
.sidebar .search-box .form-group button,
.news-widget .post .date,
.main-footer .services-list li a:hover:before,
.main-footer .services-list li a:hover,
.main-footer .about-widget .social-icon li a:hover,
.call-to-action.style-two .btn-box .theme-btn:hover,
.call-to-action .title-box h3 span,
.map-data h6,
.contact-info ul li:hover .icon,
.contact-info ul li p > span,
.contact-info ul li strong,
.contact-info ul li a:hover,
.pricing-table .price span,
.pricing-table .title h3,
.styled-pagination li a:hover,
.styled-pagination li a.active,
.post-share-options .next-prev a:hover,
.post-share-options .social-links li a:hover,
.post-share-options h4,
.news-block-two .content .read-more:hover,
.news-block-two .content h3 a:hover,
.news-block-two .info-box li a:hover,
.testimonial-block-three .rating span,
.testimonial-carousel-two .owl-prev:hover, 
.testimonial-carousel-two .owl-next:hover,
.testimonial-carousel-two .owl-nav:before,
.testimonial-block-two .info-box .rating i,
.team-block .info-box .name a:hover,
.news-block .content-box .read-more
.news-block .content-box .info li a:hover,
.news-block .content-box h3 a:hover,
.subscribe-section .call-btn:hover,
.accordion-box .block .acc-btn.active,
.accordion-box .block .acc-btn .icon,
.project-info .social-icons a:hover,
.project-info .social-icons .follow,
.project-info .info strong,
.project-info .info a:hover,
.project-detail .check-list li:before,
.project-tab .tab-btns .tab-btn.active-btn,
.project-tab .tab-btns .tab-btn:hover,
.gallery-item .icon-box h3 a:hover,
.mixitup-gallery .filters li.active,
.mixitup-gallery .filters li:hover
.feature-tabs .tabs-content ul li:before,
.feature-tabs .tabs-content .title,
.brochure-box .link-box a span,
.service-detail ul li:before,
.service-detail blockquote cite,
.services-list .service:hover h3 a,
.service-block-two .read-more,
.service-block-two h3 a:hover,
.service-block-two:hover .icon-box,
.features-carousel .owl-prev:hover, 
.features-carousel .owl-next:hover,
.feature-block .lower-content h3 a:hover,
.fun-fact-two .count-box .counter-title h3,
.fun-fact-two .count-box .count-text,
.about-us .image-column .column .read-more,
.about-us .image-column .column h3 a:hover,
.about-us .content-column .primary-text,
.page-title .bread-crumb li a:hover,
.page-title .bread-crumb li,
.sec-title .separator,
.header-style-four .main-menu .navigation > li:hover > a,
.header-style-four .main-menu .navigation > li.current > a,
.header-style-four .header-top .top-right p i,
.header-style-four .header-top .top-left ul li i,
.header-style-three .header-top .top-right p i,
.header-style-three .header-top .top-left ul li i,
.header-style-two .header-upper .main-menu .navigation > li.current > a,
.header-style-two .header-upper .main-menu .navigation > li:hover > a,
.main-header .search-panel .search-btn:hover,
.main-header .search-box-btn:hover,
.main-menu .navigation > li.current-menu-item > a,
.main-header .info-box li a:hover,
.btn-style-five:hover,
.btn-style-four,
.btn-style-one:hover,
.list-style-two li .icon,
.list-style-one li:before,
.theme_color,
a{
	color: #515ba2;
}

/*Background Color*/
.news-block-two .date-and-like .like,
.ui-state-active, .ui-widget-content .ui-state-active, 
.ui-widget-header .ui-state-active, a.ui-button:active, 
.ui-button:active, .ui-button.ui-state-active:hover,
.order-box .title-box,
.sidebar-widget .range-slider-one .ui-state-default,
.sidebar-widget .range-slider-one .ui-widget-content .ui-state-default,
.shop-item .overlay-box .cart-btn:hover,
.tag-list li a:hover,
.main-footer .gallery-widget .image a:before,
.contact-info-section,
.contact-form .form-group input[type="submit"],
.contact-form button,
.check-box input[type="checkbox"]:checked + label:before,
.vehicle-detail .range-slider-one .ui-state-default:before,
.team-block-two .inner-box:hover .caption-box,
.team-block-two .social-links li a:hover,
.styled-pagination li a.next:hover,
.styled-pagination li a.prev:hover,
.testimonial-block-two .image-box:after,
.testimonial-carousel-three .owl-dots .owl-dot.active,
.testimonial-carousel-three .owl-dots .owl-dot:hover,
.testimonial-carousel .owl-prev:hover, 
.testimonial-carousel .owl-next:hover,
.testimonial-block .icon,
.team-block .social-icon,
.news-carousel .owl-prev:hover, 
.news-carousel .owl-next:hover,
.news-block .label .likes,
.subscribe-section .inner-container,
.video-and-faqs .video-column .image .link span,
.gallery-item .icon-box .link:hover .icon,
.feature-tabs .tab-btn.active-btn,
.service-cat-list li.active a,
.service-cat-list li a:hover,
.service-detail .video-column .image .link span,
.services-list .service:hover .icon-box,
.feature-block .lower-content .read-more:hover,
.feature-block .image-box .price,
.fun-fact-two .count-box:nth-child(4n + 4) .inner-box,
.fun-fact-two .count-box:nth-child(4n + 3) .count-text:before,
.fun-fact-two .count-box:nth-child(4n + 2) .count-text:before,
.fun-fact-section .count-box .counter-title h5,
.main-header .cart-btn .count,
.header-style-two .header-top:before,
.main-header .sticky-header .main-menu .navigation > li:hover > a,
.main-header .sticky-header .main-menu .navigation > li.current > a,
.main-header .sticky-header .main-menu .navigation > li.current-menu-item > a,
.main-menu .navigation > li > ul > li  > ul > li > a:hover,
.main-menu .navigation > li > ul > li:hover > a,
.main-header .header-top,
.scroll-to-top:hover,
.btn-style-five,
.btn-style-four:hover,
.btn-style-two:hover,
.btn-style-one,
.main-menu .navigation > li.current > a{
	background-color: #515ba2;
}


.service-block .caption-box{
	background-color: rgba(28,99,184, .80);
}

/*Border Color*/
.service-form .form-group input:focus,
.service-form .form-group textarea:focus,
.ui-state-active, .ui-widget-content .ui-state-active, 
.ui-widget-header .ui-state-active, a.ui-button:active, 
.ui-button:active, .ui-button.ui-state-active:hover,
.login-form .form-group input[type="text"]:focus,
.login-form .form-group input[type="password"]:focus,
.login-form .form-group input[type="tel"]:focus,
.login-form .form-group input[type="email"]:focus,
.login-form .form-group select:focus,
.login-form .form-group textarea:focus,
.shipping-form .form-group input[type="text"]:focus,
.shipping-form .form-group input[type="password"]:focus,
.shipping-form .form-group input[type="tel"]:focus,
.shipping-form .form-group input[type="email"]:focus,
.shipping-form .form-group select:focus,
.shipping-form .form-group textarea:focus,
.checkout-form input:focus,
.checkout-form select:focus,
.checkout-form textarea:focus,
.product-info-tabs .prod-tabs .add-review .form-group textarea:focus,
.product-info-tabs .prod-tabs .add-review .form-group input:focus,
.product-info-tabs .prod-tabs .add-review .form-group select:focus,
.shop-comment-form .form-group input[type="text"]:focus,
.shop-comment-form .form-group input[type="password"]:focus,
.shop-comment-form .form-group input[type="tel"]:focus,
.shop-comment-form .form-group input[type="email"]:focus,
.shop-comment-form .form-group select:focus,
.shop-comment-form .form-group textarea:focus,
.comment-form .form-group input:focus,
.comment-form .form-group textarea:focus,
.tag-list li a:hover,
.sidebar .search-box .form-group input:focus,
.contact-info ul li:hover .icon,
.contact-form .form-group input:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus,
.check-box input[type="checkbox"]:checked + label:before,
.check-box input[type="checkbox"]:checked + label,
.appointment-form .form-group input:focus,
.appointment-form .form-group select:focus,
.appointment-form .form-group textarea:focus,
.pricing-table.tagged .inner-box,
.pricing-table .inner-box:hover,
.styled-pagination li a.next:hover,
.styled-pagination li a.prev:hover,
.styled-pagination li a:hover,
.styled-pagination li a.active,
.blog-detail blockquote,
.testimonial-carousel .owl-prev:hover, 
.testimonial-carousel .owl-next:hover,
.testimonial-block.even:before,
.testimonial-block .thumb,
.testimonial-block:before,
.team-block .info-box,
.team-block .image-box:before,
.gallery-item .icon-box .link:hover .icon,
.feature-tabs .tab-btn.active-btn,
.services-list .service:hover .icon-box,
.fun-fact-section .count-box .inner-box,
.main-header .search-panel input:focus,
.main-header .search-panel select:focus,
.main-menu .navigation > li > ul > li > ul,
.main-menu .navigation > li > ul,
.btn-style-five:hover,
.btn-style-four:hover{
	border-color: #515ba2;
}

.main-header  .dropdown-menu{
	border-top-color: #515ba2;
}

.testimonial-block.even .icon:after,
.testimonial-block .icon:after,
.fun-fact-section .count-box .counter-title h5:before{
	border-bottom-color: #515ba2;
}

.main-header .search-box-btn {
   border: 1px dashed #515ba2;
}

@media only screen and (max-width: 767px){
	
	.main-menu .navbar-header .navbar-toggle{
		border-color:#515ba2;
	}
	
	.main-menu .navbar-header .navbar-toggle,
	.main-menu .navbar-collapse > .navigation,
	.main-menu .navbar-collapse > .navigation > li > ul,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul,
	.main-menu .navbar-collapse > .navigation > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li > a,
	.main-menu .navbar-collapse > .navigation > li > a:hover,
	.main-menu .navbar-collapse > .navigation > li > a:active,
	.main-menu .navbar-collapse > .navigation > li > a:focus,
	.main-menu .navbar-collapse > .navigation > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li.current > a,
	.main-menu .navbar-collapse > .navigation > li.current-menu-item > a,
	.header-style-two.inner-header .main-menu .navigation > li > a{
		background-color:#515ba2;
	}	
}

@media (max-width: 991px){
	.about-us .image-column {
		padding-top: 0px;
	}
}

.service-block.fornecedor .image-box figure {
    width: 100%;
    height: 150px;
    position: relative;
}
.service-block.fornecedor .image-box figure a {
    position: absolute;
    top: 50%;
    -moz-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
         transform: translateY(-50%);
}

.no-padding-imp {
	padding: 0 !important;
}